import config from 'config'

import { fork, select } from 'redux-saga/effects'
import apiRequest from '../../common/apiRequest'
import { getFormValues } from 'app/redux-base/selectors/configurator'
import { PUBLISH_WHITELABEL } from 'app/redux-base/actions/configurator'

export default function* publishWL(action) {
  const state = yield select()
  const wlConfig = getFormValues(state)

  const url = `${config.BASE_URL_V2}/whitelabels/whitelabel/${wlConfig.id}/`
  const params = {
    is_live: action.isLive,
  }

  const apiCall = apiClient => apiClient.patch(url, params)
  yield apiCall

  yield fork(apiRequest, apiCall, PUBLISH_WHITELABEL, action)
}
