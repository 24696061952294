import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { Container, Layout, Flex } from 'app/components/base/Layout'
import Navigation from 'app/components/containers/Navigation'
import ConfiguratorContainer from './ConfiguratorContainer'
import PreviewContainer from './PreviewContainer'
import { CONFIGURATION_FORM_NAME } from 'app/constants'
import requireAuth from 'app/components/HOCs/requireAuth'
import validate from './ConfiguratorContainer/form/validate'

import style from './style.module.css'

export class ConfigurationPage extends React.Component {
  render() {
    const { whitelabelId } = this.props
    return (
      <Layout fill nowrap direction="column" className={style.container}>
        <Flex size="noshrink">
          <Navigation />
        </Flex>
        <Container size="grow" direction="row" nowrap className={style.content}>
          <Flex size="noshrink" className={style.content__confWrapper}>
            <ConfiguratorContainer whitelabelId={whitelabelId} />
          </Flex>
          <Container size="grow" className={style.content__previewWrapper}>
            <PreviewContainer />
          </Container>
        </Container>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// ConfigurationPage.propTypes = {
//   dispatch: PropTypes.func.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
//   valid: PropTypes.bool.isRequired,
//   whitelabelId: PropTypes.number,
//   hasUnsavedChanges: PropTypes.bool,
// }

const mapStateToProps = (state, ownProps) => ({
  whitelabelId: +ownProps.match?.params.id,
})

export const appliedHocs = [
  requireAuth(),
  reduxForm({
    form: CONFIGURATION_FORM_NAME,
    validate,
  }),
  connect(mapStateToProps),
]

export default compose(...appliedHocs)(ConfigurationPage)
