import postcss from 'postcss'
import postcssNested from 'postcss-nested'
import postcssVariables from 'postcss-advanced-variables'
import postcssColorFunctions from 'postcss-color-function'
import axios from 'axios'
import _groupBy from 'lodash/groupBy'
import _fromPairs from 'lodash/fromPairs'

export const editableFields = fields =>
  fields.filter(field => field.editable !== false)

export const defaultValues = fields =>
  editableFields(fields).reduce(
    (result, obj) => ({
      ...result,
      [obj.name]: obj.default,
    }),
    {}
  )

export const getGroups = fields => {
  const groups = _groupBy(fields, 'type')
  const groupsArray = Object.keys(groups).reduce(
    (arr, key) =>
      arr.concat({
        type: key,
        fields: groups[key],
      }),
    []
  )

  return groupsArray
}

export const convertFileToDataurl = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', () => {
      resolve(reader.result)
    })
  })
}

export const uploadFileToS3 = async (file, signer) => {
  const sign = await signer(file.type, file.name)
  const url = sign.form_action
  delete sign.form_action

  const form = new FormData()
  Object.keys(sign).forEach(key => {
    form.append(key, sign[key])
  })
  form.append('file', file)

  const res = await axios.post(url, form, {})
  const match = res.data.match(/<Key>(.*?)<\/Key>/im)

  if (match && match.length > 0) {
    return `${url}/${match[1]}`
  }

  throw new Error(`Can't upload the file: ${file.name}`)
}

export const replaceFilesByProcessor = async (
  userConfig,
  processor,
  ...args
) => {
  // Produce pairs of key and dataurl where value of key
  // is instance of File
  const fileKeys = Object.keys(userConfig).filter(
    k => userConfig[k] instanceof File
  )
  const fileUrlPairs = await Promise.all(
    fileKeys.map(k => {
      const file = userConfig[k]
      return processor(file, ...args).then(data => [k, data])
    })
  )

  // Create result config with replaced files to dataurls
  return {
    ...userConfig,
    ..._fromPairs(fileUrlPairs),
  }
}

export const processConfig = async (
  configurableJson,
  userConfig,
  processors
) => {
  const processedValues = await processors.reduce(
    async (currConfigPromise, proc) => {
      const currConfig = await currConfigPromise
      return proc(currConfig)
    },
    Promise.resolve(userConfig)
  )

  return {
    ...defaultValues(configurableJson.fields),
    ...processedValues,
  }
}

export const getPreviewConfig = (configurableJson, userConfig) => {
  return processConfig(configurableJson, userConfig, [
    config => replaceFilesByProcessor(config, convertFileToDataurl),
  ])
}

export const getPublishConfig = (configurableJson, userConfig, signer) => {
  return processConfig(configurableJson, userConfig, [
    config => replaceFilesByProcessor(config, uploadFileToS3, signer),
  ])
}

export const getThemeCss = (template, config) => {
  const postCssConfig = [
    postcssNested,
    postcssVariables({ variables: config }),
    postcssColorFunctions,
  ]
  return postcss(postCssConfig)
    .process(template)
    .then(result => Promise.resolve(result.css))
}

export const isFieldConfigurable = fields => fieldName =>
  Object.keys(defaultValues(fields)).includes(fieldName)

export const renderEmails = (emailTemplates, config) => {
  const actualConfig = { ...config }
  if (actualConfig.COLORS_DARK_MODE) {
    actualConfig.EMAIL_HEADER_BACKGROUND_COLOR =
      actualConfig.FIRST_BACKGROUND_COLOR
  } else {
    actualConfig.EMAIL_HEADER_BACKGROUND_COLOR = '#FFFFFF'
  }
  if (!actualConfig.CUSTOM_LOGO) {
    actualConfig.CUSTOM_LOGO = 'https://static.mozio.com/assets/logo.svg'
  }

  return emailTemplates.map(tpl => {
    return tpl.replace(/\${([^}]+)}/g, (match, name) => {
      return typeof actualConfig[name] !== 'undefined'
        ? actualConfig[name]
        : match
    })
  })
}
