export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS,
  }
}

export function showNotification(options) {
  return {
    type: SHOW_NOTIFICATION,
    payload: options,
  }
}
