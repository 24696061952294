import React from 'react'
import { Layout, Flex } from 'app/components/base/Layout'
import Icon from '../Icon'
import Button from 'app/components/base/Button'
import classnames from 'classnames'

import style from './style.module.css'

class FileInput extends React.Component {
  get fileName() {
    const { value } = this.props.input
    if (value instanceof File) {
      return value.name
    }
    if (typeof value === 'string') {
      return value
    }
    return undefined
  }

  handleChangeFile = e => {
    const file = e.target.files[0]
    const { onChange } = this.props.input

    if (onChange) {
      onChange(file)
    }
  }

  handleClickUpload = () => {
    this.fileInputDOM.click()
  }

  handleClearValue = () => {
    const { onChange } = this.props.input

    if (onChange) {
      onChange('')
    }
  }

  renderSelectedFile = () => (
    <Layout
      nowrap
      align="start center"
      className={classnames(style.selectedFile, {
        [style['selectedFile--selected']]: !!this.fileName,
      })}
    >
      <Flex size="noshrink">
        <Icon className={style.selectedFile__icon}>photo</Icon>
      </Flex>
      <Flex size="grow">
        <input
          disabled
          type="text"
          className={style.selectedFile__input}
          value={this.fileName}
          placeholder={this.props.placeholderText}
        />
        <input
          ref={c => (this.fileInputDOM = c)}
          type="file"
          className={style.selectedFile__fileInput}
          accept={this.props.accept}
          onChange={this.handleChangeFile}
        />
      </Flex>
      {!!this.fileName && (
        <Flex
          className={style.selectedFile__clearWrapper}
          size="noshrink"
          onClick={this.handleClearValue}
        >
          <Icon className={style.selectedFile__clear}>close</Icon>
        </Flex>
      )}
    </Layout>
  )

  renderInfoLine = () => (
    <Flex className={style.infoLine}>
      <Icon className={style.infoLine__icon}>info</Icon>
      <span className={style.infoLine__text}>{this.props.fileInfoLabel}</span>
    </Flex>
  )

  render() {
    const { className, fileInfoLabel } = this.props
    return (
      <Layout
        nowrap
        size="noshrink"
        direction="column"
        className={classnames(className, style.container)}
      >
        <Layout nowrap size="noshrink" direction="row">
          <Flex size="grow">{this.renderSelectedFile()}</Flex>
          <Flex size="noshrink">
            <Button size="small" onClick={this.handleClickUpload}>
              Upload file
            </Button>
          </Flex>
        </Layout>
        {!!fileInfoLabel && this.renderInfoLine()}
      </Layout>
    )
  }
}

FileInput.defaultProps = {
  placeholderText: 'No file uploaded yet',
  accept: 'image/jpeg,image/jpg,image/png,image/x-icon,image/gif',
}

// TODO: move to Flow types
// FileInput.propTypes = {
//   accept: PropTypes.string,
//   className: PropTypes.string,
//   fileInfoLabel: PropTypes.string,
//   placeholderText: PropTypes.string,
//   // redux-form field props
//   input: PropTypes.shape({
//     value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//     onChange: PropTypes.func.isRequired,
//   }),
// }

export default FileInput
