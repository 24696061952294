import { connect } from 'react-redux'
import { getOpenedPaletteItem } from 'app/redux-base/selectors/palette'
import { togglePaletteItem } from 'app/redux-base/actions/palette'
import ColorPaletteItem from './ColorPaletteItem'

const mapStateToProps = (state, props) => ({
  opened: getOpenedPaletteItem(state) === props.index,
})
const mapDispatchToProps = {
  onClick: togglePaletteItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorPaletteItem)
