import appConfig from 'config'
// import delay from '@redux-saga/delay-p'
import { call, put } from 'redux-saga/effects'
import apiRequest from '../../common/apiRequest'
import { CLONE_WHITELABEL } from 'app/redux-base/actions/configurator'
import NotificationType from 'app/redux-base/enums/NotificationType'
import {
  showNotification,
  clearNotifications,
} from 'app/redux-base/actions/notifications'

export default function* cloneWL(action) {
  const {
    payload: { wlId, name },
  } = action
  yield put(clearNotifications())

  const url = `${appConfig.BASE_URL_V2}/whitelabels/whitelabel/${wlId}/clone/`
  const params = { name }

  const apiCall = apiClient => apiClient.post(url, params)
  yield apiCall

  const { response, error } = yield call(
    apiRequest,
    apiCall,
    CLONE_WHITELABEL,
    action
  )

  if (response) {
    yield put(
      showNotification({
        type: NotificationType.INFO,
        message: 'WL successfully cloned',
      })
    )

    action.resolvePromise()
  } else {
    // show error
    yield put(
      showNotification({
        type: NotificationType.ERROR,
        title: 'Cloning of WL failed',
        message: error.message || 'unkonwn server error',
      })
    )

    action.rejectPromise()
  }
}
