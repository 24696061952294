import { fork } from 'redux-saga/effects'
import loginFlow from './loginFlow'
import configurator from './configurator'
import watchCreateWL from './watchCreateWL'
import dashboard from './dashboard'

export default function* rootSaga() {
  yield fork(loginFlow)
  yield fork(configurator)
  yield fork(dashboard)
  yield fork(watchCreateWL)
}
