import React from 'react'
import classnames from 'classnames'

import style from './style.module.css'

/**
 * Icon component for shorthanding `<i className="material-icons">{name}</i>`.
 * @param  {Boolean} tilt     rotate 45deg clockwise
 * @return {Component}
 */
const Icon = ({ children, className, tilt, ...props }) => (
  <i
    {...props}
    className={classnames('material-icons', className, { [style.tilt]: tilt })}
  >
    {children}
  </i>
)

// TODO: move to Flow types
// Icon.propTypes = {
//   children: PropTypes.node,
//   tilt: PropTypes.bool,
//   className: PropTypes.string,
// }

export default Icon
