import React from 'react'
import { connect } from 'react-redux'
import Navigation from 'app/components/containers/Navigation'
import Footer from 'app/components/base/Footer'
import Button from 'app/components/base/Button'
import { Layout, Flex } from 'app/components/base/Layout'
import { withRouter } from 'react-router'

import style from './style.module.css'

const HomePage = ({ history }) => (
  <Layout fill nowrap direction="column">
    <Flex size="noshrink">
      <Navigation />
    </Flex>
    <Layout size="grow" align="center center" className={style.header}>
      <Flex className={style.header__container}>
        <h1 className={style.header__h1}>
          Earn more money by selling ground
          <br />
          transportation tickets on post-booking stage
        </h1>
        <Button
          size="large"
          color="secondary"
          onClick={() => history.push('/create')}
        >
          Create whitelabel
        </Button>
      </Flex>
    </Layout>
    <Flex size="noshrink">
      <Footer />
    </Flex>
  </Layout>
)

export default withRouter(connect()(HomePage))
