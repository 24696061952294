import { fork } from 'redux-saga/effects'
import watchUpdatePreview from './watchUpdatePreview'
import watchPublishWL from './watchPublishWL'
import watchLoadWL from './watchLoadWL'
import watchSaveWL from './watchSaveWL'
import watchConfirmDeleteWL from './watchConfirmDeleteWL'
import watchCreateGaProperty from './watchCreateGaProperty'
import watchCloneWL from './watchCloneWL'

export default function* configurator() {
  yield fork(watchUpdatePreview)
  yield fork(watchPublishWL)
  yield fork(watchLoadWL)
  yield fork(watchSaveWL)
  yield fork(watchConfirmDeleteWL)
  yield fork(watchCreateGaProperty)
  yield fork(watchCloneWL)
}
