import createRequestTypes from 'app/utils/createRequestTypes'
import { addControllablePromise } from 'app/utils/redux'

export const CREATE_WHITELABEL = createRequestTypes('CREATE_WHITELABEL')

export function createWhitelabelRequest(formData) {
  return addControllablePromise({
    type: CREATE_WHITELABEL.REQUEST,
    payload: formData,
  })
}
