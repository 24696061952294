/* eslint-disable max-len */
export const DOMAIN_REGEXP = /^[a-zA-Z0-9-]{2,}$/
/* eslint-enable max-len */

const validate = ({ name, currency }) => {
  const errors = {}

  if (!name) {
    errors.name = 'Required'
  } else if (!DOMAIN_REGEXP.test(name)) {
    errors.name = 'Invalid name'
  }

  if (!currency) {
    errors.currency = 'Required'
  }

  return errors
}

export default validate
