import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import userSelector from 'app/redux-base/selectors/user'

export default function checkAuth(options = {}) {
  return Wrapped => {
    class AuthenticatedComponent extends Component {
      componentDidMount() {
        this.checkAndRedirect()
      }

      componentDidUpdate() {
        this.checkAndRedirect()
      }

      checkAndRedirect() {
        const { user, history } = this.props

        const redirectPath = options.redirectTo || '/login'

        if (!user) {
          history.replace(redirectPath)
        }
      }

      render() {
        return this.props.user ? <Wrapped {...this.props} /> : null
      }
    }

    // TODO: move to Flow types
    // AuthenticatedComponent.propTypes = {
    //   user: PropTypes.object,
    // }

    const mapStateToProps = state => {
      return {
        ...userSelector(state),
      }
    }

    return withRouter(connect(mapStateToProps)(AuthenticatedComponent))
  }
}
