import cookies from 'js-cookie'
import atob from 'atob'
import * as config from 'app/constants'

// Utils
function getTokenFromCookie() {
  return cookies.get(config.apiTokenCookieName)
}

function getTokenPayload(token) {
  return token ? JSON.parse(atob(token.split('.')[1])) : {}
}

// class for working with JWT tokens
// http://jwt.io/
export default class ApiToken {
  constructor(tokenString) {
    this.setToken(tokenString)
  }

  get token() {
    return this.internalToken
  }

  set token(newToken) {
    this.setToken(newToken)
  }

  get isExpired() {
    // if token and payload is not set, expiration date will be 'Invalid Date'
    return !this.expirationDate || new Date() > this.expirationDate
  }

  get expirationDate() {
    return new Date((this.payload.exp || 0) * 1000)
  }

  setToken(token) {
    this.internalToken = token
    this.payload = getTokenPayload(this.token)
  }

  getUser = () => {
    if (!this.token) {
      return null
    }

    return {
      email: this.payload.email,
      username: this.payload.username,
      token: this.token,
    }
  }

  getFromCookie = () => {
    const token = getTokenFromCookie()
    this.setToken(token)

    return this
  }

  setCookie = () => {
    cookies.set(config.apiTokenCookieName, this.token, {
      expires: this.expirationDate,
    })

    return this
  }

  removeCookie = () => {
    cookies.remove(config.apiTokenCookieName)
  }
}
