import React from 'react'
import { Layout, Flex } from 'app/components/base/Layout'
import Icon from 'app/components/base/Icon'
import ColorPicker from './ColorPicker'
import ValidationError from 'app/components/base/ValidationError'
import classnames from 'classnames'

import style from './style.module.css'

// Utils
const HEX_COLOR_REGEXP = /(^[0-9A-F]{6}$)|(^[0-9A-F]{3}$)/i
const stripValueHash = val => (val && val[0] === '#' ? val.substr(1) : val)
const isHexColor = h => {
  return HEX_COLOR_REGEXP.test(h)
}

/**
 * Component for showing color picker. It works like an input and waiting
 * for `value` and `onChange` props. Also you should proved `label`
 * prop to specify color name in a header.
 */
export class ColorPaletteItem extends React.Component {
  handleChangeColor(colorHex) {
    const { onChange } = this.props.input
    let value = stripValueHash(colorHex)
    value = isHexColor(value) ? `#${value}` : value
    if (onChange) {
      onChange(value)
    }
  }

  handleClickItem = () => {
    const { onClick } = this.props
    if (onClick) {
      onClick(this.props.index)
    }
  }

  handleChangeColorPalette = color => {
    this.handleChangeColor(color.hex)
  }

  handleChangeColorInput = e => {
    this.handleChangeColor(e.target.value)
  }

  handleBlurColorInput = () => {
    // blur event should be triggered for validation,
    // but blur event also set value of the input to a field
    // which is not needed because field value should be post processed
    const { onBlur } = this.props.input
    if (onBlur) {
      onBlur()
    }
  }

  handleClickValue = () => {
    this.input.focus()
  }

  render() {
    const {
      className,
      input: { value },
      label,
      opened,
    } = this.props

    return (
      <div className={style.container}>
        <Layout
          nowrap
          direction="column"
          className={classnames(className, style.item, {
            [style['item--opened']]: opened,
          })}
        >
          <Layout nowrap className={style.item__wrapper}>
            <Flex
              size="grow"
              className={style.item__padder}
              onClick={this.handleClickValue}
            >
              <div className={style.item__label}>{label}</div>
              <div>
                <label className={style.item__valueHash}>#</label>
                <input
                  ref={e => (this.input = e)}
                  value={stripValueHash(value)}
                  className={style.item__value}
                  onChange={this.handleChangeColorInput}
                  onBlur={this.handleBlurColorInput}
                />
              </div>
            </Flex>
            <Flex
              className={style.item__colorContainer}
              onClick={this.handleClickItem}
              size="noshrink"
            >
              <div
                className={style.item__color}
                style={{ backgroundColor: value }}
              >
                <Icon className={style.item__colorIcon}>tune</Icon>
              </div>
            </Flex>
          </Layout>
          {!opened ? null : (
            <Flex size="noshrink" className={style.item__picker}>
              <ColorPicker
                onSelect={this.handleClickItem}
                color={value}
                onChange={this.handleChangeColorPalette}
              />
            </Flex>
          )}
        </Layout>
        <ValidationError field={this.props} className={style.item__error} />
      </div>
    )
  }
}

// TODO: move to Flow types
// ColorPaletteItem.propTypes = {
//   index: PropTypes.any,
//   className: PropTypes.string,
//   label: PropTypes.node,
//   opened: PropTypes.bool,
//   onBlur: PropTypes.func,
//   onClick: PropTypes.func,
//   // redux-form field props
//   input: PropTypes.shape({
//     value: PropTypes.string,
//     onChange: PropTypes.func.isRequired,
//     onBlur: PropTypes.func.isRequired,
//   }),
// }

export default ColorPaletteItem
