import NotificationType from '../enums/NotificationType'
import {
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../actions/notifications'

const initialState = {
  message: null,
  messageId: null,
  title: null,
  titleId: null,
  type: NotificationType.INFO,
}

export default function notifications(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const {
        payload: { type, message, title },
      } = action
      return {
        type,
        message,
        title,
      }
    }

    case CLEAR_NOTIFICATIONS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
