// @flow
import React from 'react'
import Lightbox from 'mz-ui-kit/core/Lightbox'
import Form from 'mz-ui-kit/form/Form'
import TextField from 'mz-ui-kit/form/TextField/field'
import { Layout } from 'app/components/base/Layout'
import Button from 'app/components/base/Button'
import { cloneWhitelabel } from 'app/redux-base/actions/configurator'
import { DOMAIN_REGEXP } from '../../CreateWhitelabelPage/form/validate'
import style from './style.module.css'

type State = {
  isOpened: boolean,
}
type Props = {
  whitelabelId: string,
  dispatch: Function,
}
type FormValues = {
  name: string,
}

const validateName = name => {
  if (!DOMAIN_REGEXP.test(name)) {
    return 'Invalid name'
  }
  return null
}

class CloneDialog extends React.Component<Props, State> {
  state = {
    isOpened: false,
  }

  handleOnCloneClick = ({ name }: FormValues) => {
    const { dispatch, whitelabelId } = this.props
    const { promise, ...action } = cloneWhitelabel(whitelabelId, name)
    dispatch(action)
    return promise.then(this.hide)
  }

  show = () => {
    this.setState({ isOpened: true })
  }

  hide = () => {
    this.setState({ isOpened: false })
  }

  render() {
    return (
      <Lightbox
        onClose={this.hide}
        showed={this.state.isOpened}
        render={() => (
          <Form
            onSubmit={this.handleOnCloneClick}
            render={({ submit, meta }, nest) => (
              <form onSubmit={submit}>
                <div className={style.deleteDialogContainer}>
                  <Layout
                    direction="column"
                    align="center center"
                    className={style.deleteDialog}
                  >
                    <span className={style.deleteDialog__iconLabel}>
                      CLONING WHITELABEL
                    </span>
                    <p>
                      Enter name for new WL that will contain the same settings
                      as this one
                    </p>
                    <TextField
                      {...nest('name')}
                      appearance="btp-field-outlined"
                      validate={validateName}
                      required
                    />
                    <Button
                      size="small"
                      type="submit"
                      className={style.deleteDialog__button}
                      onClick={submit}
                      loading={meta.loading}
                    >
                      CONFIRM
                    </Button>
                  </Layout>
                </div>
              </form>
            )}
          />
        )}
      />
    )
  }
}

// TODO: move to Flow types
// DeleteDialog.propTypes = {
//   dispatch: PropTypes.func.isRequired,
//   loading: PropTypes.bool,
//   whitelabelId: PropTypes.number.isRequired,
// }

export default CloneDialog
