import React from 'react'
import { Route } from 'react-router-dom'
import { Router } from 'react-router'
import { getHistory } from 'app/history'

import AppPage from './AppPage'
import HomePage from './HomePage'
import DashboardPage from './DashboardPage'
import LoginPage from './LoginPage'
import SignupPage from './SignupPage'
import ConfigurationPage from './ConfigurationPage'
import CreateWhitelabelPage from './CreateWhitelabelPage'

const Routes = props => (
  <Router {...props} history={getHistory()}>
    <AppPage>
      <Route exact path="/" component={HomePage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/dashboard" component={DashboardPage} />
      <Route path="/configuration/:id" component={ConfigurationPage} />
      <Route path="/create" component={CreateWhitelabelPage} />
    </AppPage>
  </Router>
)

export default Routes
