import React from 'react'
import FileInput from 'app/components/base/FileInput'
import FieldBase from '../../commons/FieldBase'

export const ImageRenderer = ({ field, ...props }) => (
  <FieldBase label={field.description} direction="column">
    <FileInput {...props} />
  </FieldBase>
)

// TODO: move to Flow types
// ImageRenderer.propTypes = {
//   field: PropTypes.object,
// }

export default ImageRenderer
