import React from 'react'
import { Layout, Flex } from 'app/components/base/Layout'
import { Field } from 'redux-form'
import Button from 'app/components/base/Button'
import TextField from 'mz-ui-kit/form/TextField'
import Checkbox from 'mz-ui-kit/form/CheckboxField'

import style from './style.module.css'

const normalizePartnerRef = value => {
  if (!value) return value
  return value.replace(/[^\-a-z0-9]+/gi, '').toLowerCase()
}

const Title = ({ text }) => <div className={style.form__title}>{text}</div>

const CancelButton = ({ text, onCancel }) => (
  <Button
    compact
    className={style.form__cancel_button}
    size="small"
    onClick={onCancel}
  >
    {text}
  </Button>
)

const SubmitButton = ({ onSubmit, text, loading }) => (
  <Button
    color="secondary"
    onClick={onSubmit}
    className={style.form__submit_button}
    loading={loading}
  >
    {text}
  </Button>
)

const CreateForm = ({ onSubmit, onCancel, loading }) => (
  <Layout direction="column" className={style.form}>
    <Layout
      nowrap
      direction="row"
      align="bottom center"
      className={style.form__header}
    >
      <Flex className={style.form__header_left}>
        <Title text="New Whitelabel" />
      </Flex>
      <Flex className={style.form__header_right}>
        <CancelButton onCancel={onCancel} text="Cancel" />
      </Flex>
    </Layout>
    <Layout direction="column" className={style.form__body}>
      <Layout nowrap direction="row">
        <Flex>
          <Field
            name="name"
            component={TextField}
            label="Whitelabel Name"
            renderPostfix={() => (
              <span className={style.form__name_field_lettering}>
                .MOZIO.COM
              </span>
            )}
            disableFloatingLabel
          />
        </Flex>
      </Layout>
      <Layout nowrap direction="row">
        <Flex>
          <Field
            name="partnerRef"
            component={TextField}
            label="Custom partner ref (Optional)"
            placeholder="If left empty the ref will be guessed from WL name"
            normalize={normalizePartnerRef}
            disableFloatingLabel
          />
        </Flex>
      </Layout>
      <Layout nowrap direction="row" className={style.form__action_checkbox}>
        <Field
          name="createGAproperty"
          component={Checkbox}
          label="Create GA property for the WL"
        />
      </Layout>
      <Layout nowrap direction="row" className={style.form__action_checkbox}>
        <Field
          name="createRef"
          component={Checkbox}
          label="Create Partner Ref"
        />
      </Layout>
      <Layout align="center center">
        <SubmitButton
          loading={loading}
          onSubmit={onSubmit}
          text="Create Whitelabel"
        />
      </Layout>
    </Layout>
  </Layout>
)

export default CreateForm
