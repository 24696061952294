import { CREATE_WHITELABEL } from 'app/redux-base/actions/createWL'

export default function create(state = {}, action = {}) {
  switch (action.type) {
    case CREATE_WHITELABEL.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CREATE_WHITELABEL.SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }

    case CREATE_WHITELABEL.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}
