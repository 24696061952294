import createRequestTypes from 'app/utils/createRequestTypes'

export const DELETE_WHITELABEL = createRequestTypes('DELETE_WHITELABEL')
export const CONFIRM_DELETE_WHITELABEL = 'CONFIRM_DELETE_WHITELABEL'

export function deleteWhitelabelRequest(id) {
  return {
    type: DELETE_WHITELABEL.REQUEST,
    id,
  }
}

export function confirmDeleteWL(id, confirmText) {
  return {
    type: CONFIRM_DELETE_WHITELABEL,
    id,
    confirmText,
  }
}
