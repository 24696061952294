import { call, select, put } from 'redux-saga/effects'
import { showNotification } from 'app/redux-base/actions/notifications'
import { getAppVersionModel } from 'app/redux-base/selectors/configurator'
import createUploadSigner from './createUploadSigner'
import { getPublishConfig } from 'app/redux-base/customizationUtils'
import NotificationType from 'app/redux-base/enums/NotificationType'

export default function* processConfig(action) {
  const { wlConfig } = action
  const state = yield select()
  const { configurable_json, id } = getAppVersionModel(state)
  const uploadSigner = yield call(createUploadSigner, action)

  try {
    const res = yield call(
      getPublishConfig,
      configurable_json,
      wlConfig.config,
      uploadSigner
    )

    return {
      config: res,
      versionId: id,
    }
  } catch (e) {
    yield put(
      showNotification({
        type: NotificationType.ERROR,
        title: 'Save whitelabel failed',
        message: 'Failed to upload images',
      })
    )

    return {}
  }
}
