import React from 'react'
import { Layout, Flex } from '../Layout'
import Logo from '../Logo'
import Link from '../Link'
import classnames from 'classnames'

import style from './style.module.css'

const directionSizes = {
  mobile: 'column',
  '> mobile': 'row',
}
const alignSizes = {
  mobile: 'start start',
  '> mobile': 'center center',
}

const columns = [
  [
    { href: 'mailto:info@mozio.com', text: 'info@mozio.com', lowercase: true },
    { href: 'tel:+18559805669', text: '+1 855 980 5669', dark: true },
    { href: 'tel:+14159697669', text: '+1 415 969 7669', dark: true },
  ],
  [
    { href: '/about', text: 'About Us' },
    { href: '/faq', text: 'FAQ' },
    { href: 'https://blog.mozio.com/', text: 'Blog' },
  ],
  [
    { href: '/partners', text: 'Partners' },
    {
      href: '/online-travel-landscape',
      text: 'Online Travel Landscape',
    },
  ],
]

/**
 * Renders a column of links in footer
 * @param  {Array} links   array of links
 * @return {Component}
 */
export const LinksColumn = ({ className, links, ...props }) => (
  <Flex className={classnames(className, style.column)} {...props}>
    <ul className={style.column__list}>
      {links.map((l, i) => (
        <li key={i} className={style.column__item}>
          <Link
            target="_blank"
            href={l.href}
            dark={l.dark}
            uppercase={!l.lowercase}
          >
            {l.text}
          </Link>
        </li>
      ))}
    </ul>
  </Flex>
)

// TODO: move to Flow types
// LinksColumn.propTypes = {
//   className: PropTypes.string,
//   links: PropTypes.array,
// }

/**
 * Renders copyrights of mozio and privacy links
 * @return {Components}
 */
export const Copyright = () => (
  <Flex className={style.copyright}>
    <Link
      className={style.copyright__link}
      dark
      href="/privacy-policy"
      target="_blank"
    >
      Privacy Policy
    </Link>
    <Link
      className={style.copyright__link}
      dark
      href="/terms-of-service"
      target="_blank"
    >
      Terms of Service
    </Link>
    <p className={style.copyright__text}>
      <span className={style.copyright__company}>&copy; 2016 Mozio Inc. </span>
      Trademarks and brands are property of their respective owners.
    </p>
  </Flex>
)

/**
 * Renred the footer with links and copyright
 * @return {Component}
 */
export const Footer = props => (
  <Layout
    className={style.container}
    direction="column"
    align={alignSizes}
    {...props}
  >
    <Layout direction={directionSizes} nowrap>
      <Flex size="shrink">
        <Logo />
      </Flex>
      {columns.map((links, i) => (
        <LinksColumn key={i} links={links} />
      ))}
    </Layout>
    <Copyright />
  </Layout>
)

export default Footer
