import delay from '@redux-saga/delay-p'
import { call, put, select } from 'redux-saga/effects'
import {
  getFormValues,
  getAppVersionModel,
} from 'app/redux-base/selectors/configurator'
import { setConfigurationCondition } from 'app/redux-base/actions/configurator'
import { getPreviewConfig } from 'app/redux-base/customizationUtils'
import _isEqual from 'lodash/isEqual'
import updatePreviewConfigs from './updatePreviewConfigs'

export default function* updatePreview() {
  yield call(delay, 320)

  const state = yield select()
  const { configurable_json } = getAppVersionModel(state)

  const configuratorForm = getFormValues(state)

  const hasChanges = !_isEqual(
    configuratorForm,
    state.configurator.originalWlConfig
  )
  yield put(setConfigurationCondition(hasChanges))

  const wlSettings = yield call(
    getPreviewConfig,
    configurable_json,
    configuratorForm.config
  )

  yield call(updatePreviewConfigs, wlSettings)
}
