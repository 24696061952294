import React from 'react'
import { Layout, Flex, Container } from 'app/components/base/Layout'
import classnames from 'classnames'

import style from './style.module.css'

export class Preview extends React.Component {
  componentDidMount() {
    this.frame.addEventListener('load', () => this.update())
    this.setContent()
  }

  componentDidUpdate() {
    this.update()
    this.setContent()
  }

  setContent() {
    const { content } = this.props
    if (content) {
      const doc = this.frame.contentWindow.document
      doc.open()
      doc.write(content)
      doc.close()
    }
  }

  update() {
    this.frame.contentWindow.postMessage(
      {
        appConfig: this.props.appConfig,
        themeCss: this.props.themeCss,
      },
      '*'
    )
  }

  render() {
    const { className, src, ...props } = this.props

    return (
      <Layout
        nowrap
        fill
        direction="column"
        className={classnames(className, style.container)}
        {...props}
      >
        <Flex size="noshrink">
          <div className={style.header}>
            <span className={style.header__circle} />
            <span className={style.header__circle} />
            <span className={style.header__circle} />
          </div>
        </Flex>
        <Container size="grow" className={style.content}>
          <iframe
            ref={e => (this.frame = e)}
            className={style.content__iframe}
            src={src || 'about:blank'}
            width="100%"
            height="100%"
          />
        </Container>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// Preview.propTypes = {
//   content: PropTypes.string,
//   appConfig: PropTypes.object,
//   themeCss: PropTypes.string,
//   className: PropTypes.string,
//   src: PropTypes.string,
// }

export default Preview
