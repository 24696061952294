import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import promiseMiddleware from './middlewares/promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import rootReducer from './reducers'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, promiseMiddleware, sagaMiddleware]
const composeEnhancers = composeWithDevTools({})

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  // run sagas
  sagaMiddleware.run(rootSaga)
  return store
}
