import React from 'react'
import { connect } from 'react-redux'
import * as notificationActions from 'app/redux-base/actions/notifications'

import NotificationBar from 'app/components/base/NotificationBar'

const GlobalNotification = ({ clearNotifications, ...props }) => (
  <NotificationBar {...props} onClose={clearNotifications} />
)

// TODO: move to Flow types
// GlobalNotification.propTypes = {
//   // Actions
//   clearNotifications: PropTypes.func.isRequired,
//   showNotification: PropTypes.func.isRequired,
// }

export default connect(
  ({ notifications }) => notifications,
  notificationActions
)(GlobalNotification)
