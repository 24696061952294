import createRequestTypes from 'app/utils/createRequestTypes'
import { addControllablePromise } from 'app/utils/redux'

export const LOAD_LATEST_APP_VERSION = createRequestTypes(
  'LOAD_LATEST_APP_VERSION'
)

export const LOAD_EMAIL_TEMPLATES = createRequestTypes('LOAD_EMAIL_TEMPLATES')

export const LOAD_WHITELABEL = createRequestTypes('LOAD_WHITELABEL')

export const UPDATE_WHITELABEL_PREVIEW = 'UPDATE_WHITELABEL_PREVIEW'

export const PUBLISH_WHITELABEL = createRequestTypes('PUBLISH_WHITELABEL')
export const SAVE_WHITELABEL = createRequestTypes('SAVE_WHITELABEL')

export const RESTORE_WHITELABEL = 'RESTORE_WHITELABEL'

export const SET_CONFIGURATION_CONDITION = 'SET_CONFIGURATION_CONDITION'

export const SWITCH_PREVIEW_TYPE = 'SWITCH_PREVIEW_TYPE'

export const CREATE_GOOGLE_ANALYTICS_PROPERTY = createRequestTypes(
  'CREATE_GOOGLE_ANALYTICS_PROPERTY'
)

export const CLONE_WHITELABEL = createRequestTypes('CLONE_WHITELABEL')

export function loadEmailTemplatesRequest() {
  return {
    type: LOAD_EMAIL_TEMPLATES.REQUEST,
  }
}

export function loadEmailTemplatesSuccess(emails) {
  return {
    type: LOAD_EMAIL_TEMPLATES.SUCCESS,
    emails,
  }
}

export function updateWhitelabelPreview(appConfig, themeCss, emails) {
  return {
    type: UPDATE_WHITELABEL_PREVIEW,
    appConfig,
    themeCss,
    emails,
  }
}

export function loadLatestAppVersionRequest() {
  return {
    type: LOAD_LATEST_APP_VERSION.REQUEST,
  }
}

export function switchPreviewType(previewType) {
  return {
    type: SWITCH_PREVIEW_TYPE,
    previewType,
  }
}

// -----load WL

export function loadWhitelabelRequest({ id, showLoading = true }) {
  return {
    type: LOAD_WHITELABEL.REQUEST,
    payload: { id, showLoading },
  }
}

export function loadWhitelabelSuccess(id, wlConfig, originalWlConfig) {
  return {
    type: LOAD_WHITELABEL.SUCCESS,
    id,
    wlConfig,
    originalWlConfig,
  }
}

export function loadWhitelabelFailure(id, error) {
  return {
    type: LOAD_WHITELABEL.FAILURE,
    id,
    error,
  }
}

// -----create Google Analytics property
export function createGaProperty(wlId) {
  return addControllablePromise({
    type: CREATE_GOOGLE_ANALYTICS_PROPERTY.REQUEST,
    payload: { wlId },
  })
}

// -----clone WL
export function cloneWhitelabel(wlId, name) {
  return addControllablePromise({
    type: CLONE_WHITELABEL.REQUEST,
    payload: { wlId, name },
  })
}

// -----publish WL

export function publishWhitelabelRequest(isLive) {
  return {
    type: PUBLISH_WHITELABEL.REQUEST,
    isLive,
  }
}

// -----save changes in WL
export function saveWhitelabelRequest(wlConfig) {
  return addControllablePromise({
    type: SAVE_WHITELABEL.REQUEST,
    wlConfig,
  })
}

// -----remove loaded WL from state
export function restoreWhitelabel() {
  return {
    type: RESTORE_WHITELABEL,
  }
}

// -----mark configuration saved or unsaved
export function setConfigurationCondition(hasChanges) {
  return {
    type: SET_CONFIGURATION_CONDITION,
    hasChanges,
  }
}
