import React from 'react'
import FieldBase from '../../commons/FieldBase'
import ColorPalette from '../../../ColorPalette'

export const ColorRenderer = ({ field, ...props }) => (
  <FieldBase noBorder compact>
    <ColorPalette label={field.description} index={field.name} {...props} />
  </FieldBase>
)

// TODO: move to Flow types
// ColorRenderer.propTypes = {
//   field: PropTypes.object,
// }

export default ColorRenderer
