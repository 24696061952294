import React from 'react'
import Logo from '../Logo'
import { Link as RouterLink } from 'react-router-dom'
import classnames from 'classnames'

import style from './style.module.css'

/**
 * Navigation button. Should be rendered in `ButtonsGroup`
 * @param  {Boolean} small      make text very small
 * @param  {Boolean} inactive   make a button looks more like a text
 * @return {Component}
 */
export const Button = ({
  small = false,
  inactive = false,
  active = false,
  component = 'a',
  className,
  children,
  ...props
}) => (
  <li
    className={classnames(className, style.button, {
      [style['button--small']]: small,
      [style['button--inactive']]: inactive,
      [style['button--active']]: active,
    })}
  >
    {React.createElement(
      component,
      {
        className: style.button__wrapper,
        ...props,
      },
      children
    )}
  </li>
)

// TODO: move to Flow types
// Button.propTypes = {
//   small: PropTypes.bool,
//   inactive: PropTypes.bool,
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
//   component: PropTypes.any,
// }

/**
 * Shorthand for using "react-router"'s Link instead "a"
 * component
 * @return {Component}
 */
export const Link = ({ children, ...props }) => (
  <Button {...props} component={RouterLink}>
    {children}
  </Button>
)

// TODO: move to Flow types
// Link.propTypes = {
//   children: PropTypes.node,
// }

/**
 * Container for buttons in the navigation
 * @param  {String} position    "left" or "right" position in the navigation
 * @return {Component}
 */
export const ButtonsGroup = ({
  className,
  position = 'right',
  children,
  ...props
}) => (
  <ul
    className={classnames(
      className,
      style.buttons,
      style[`buttons--${position}`]
    )}
    {...props}
  >
    {children}
  </ul>
)

// TODO: move to Flow types
// ButtonsGroup.propTypes = {
//   className: PropTypes.string,
//   position: PropTypes.oneOf(['left', 'right']),
//   children: PropTypes.node,
// }

/**
 * Basic container for navigation with logo
 * @param  {Function} onClickLogo  handler for click event on logo
 * @return {Component}
 */
export const Container = ({ onClickLogo, className, children, ...props }) => (
  <header className={classnames(className, style.container)} {...props}>
    <Logo inverted className={style.logo} onClick={onClickLogo} />
    <nav className={style.nav}>{children}</nav>
  </header>
)

// TODO: move to Flow types
// Container.propTypes = {
//   className: PropTypes.string,
//   children: PropTypes.node,
//   onClickLogo: PropTypes.func,
// }

export default Container
