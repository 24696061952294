import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import Button from 'app/components/base/Button'
import Preview from 'app/components/configuration/Preview'
import config from 'config'
import { switchPreviewType } from 'app/redux-base/actions/configurator'
import PreviewType from 'app/redux-base/enums/PreviewType'
import {
  getAppConfig,
  getThemeCss,
  getWlConfig,
  getLoadingStatus,
  getEmails,
  getPreviewType,
} from 'app/redux-base/selectors/configurator'

import style from './style.module.css'

export const PreviewContainer = ({
  className,
  appConfig,
  themeCss,
  wlConfig,
  loading,
  emails,
  previewType,
  switchToSitePreview,
  switchToEmailsPreview,
  ...props
}) => (
  <div className={classnames(className, style.container)} {...props}>
    <div className={style.preview}>
      <Button
        onClick={switchToSitePreview}
        className={style.preview__button}
        disabled={previewType === PreviewType.SITE}
      >
        Site Preview
      </Button>
      <Button
        onClick={switchToEmailsPreview}
        className={style.preview__button}
        disabled={previewType === PreviewType.EMAILS}
      >
        Email Preview
      </Button>
    </div>
    <div className={style.wrapper}>
      <div className={style.subWrapper}>
        {!loading.whitelabel &&
          previewType === PreviewType.SITE &&
          wlConfig && (
            <Preview
              src={config.WL_BOOTSTRAP_URL + wlConfig.name}
              appConfig={appConfig}
              themeCss={themeCss}
            />
          )}
        {!loading.emails &&
          previewType === PreviewType.EMAILS &&
          emails.map((e, i) => (
            <Preview key={i} content={e} className={style.emailPreview} />
          ))}
      </div>
    </div>
  </div>
)

// TODO: move to Flow types
// PreviewContainer.propTypes = {
//   switchToSitePreview: PropTypes.func,
//   switchToEmailsPreview: PropTypes.func,
//   emails: PropTypes.array,
//   previewType: PropTypes.string,
//   className: PropTypes.string,
//   appConfig: PropTypes.object,
//   themeCss: PropTypes.string,
//   wlConfig: PropTypes.object,
//   loading: PropTypes.object,
// }

const mapStateToProps = state => ({
  ...getAppConfig(state),
  ...getThemeCss(state),
  emails: getEmails(state).renders,
  wlConfig: getWlConfig(state),
  loading: getLoadingStatus(state),
  previewType: getPreviewType(state),
})

const mapDispatchToProps = {
  switchToSitePreview: switchPreviewType.bind(null, PreviewType.SITE),
  switchToEmailsPreview: switchPreviewType.bind(null, PreviewType.EMAILS),
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainer)
