import React from 'react'
import { Layout, Flex } from 'app/components/base/Layout'
import classnames from 'classnames'

import style from './style.module.css'

export const FieldBase = ({
  children,
  label,
  noBorder,
  compact,
  direction = 'row',
  align = 'start start',
}) => {
  const modifiers = {
    [style['container--noBorder']]: noBorder,
    [style['container--compact']]: compact,
    [style['container--columnLabel']]: direction === 'column',
  }
  return (
    <Layout
      className={classnames(style.container, modifiers)}
      direction={direction}
      align={align}
      nowrap
    >
      {!!label && (
        <Flex size="grow">
          <span className={style.label}>{label}</span>
        </Flex>
      )}
      {children}
    </Layout>
  )
}

// TODO: move to Flow types
// FieldBase.propTypes = {
//   children: PropTypes.node,
//   label: PropTypes.node,
//   direction: PropTypes.string,
//   align: PropTypes.string,
//   noBorder: PropTypes.bool,
//   compact: PropTypes.bool,
// }

export default FieldBase
