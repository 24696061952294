import {
  UPDATE_WHITELABEL_PREVIEW,
  PUBLISH_WHITELABEL,
  SAVE_WHITELABEL,
  LOAD_LATEST_APP_VERSION,
  LOAD_EMAIL_TEMPLATES,
  LOAD_WHITELABEL,
  RESTORE_WHITELABEL,
  SET_CONFIGURATION_CONDITION,
  SWITCH_PREVIEW_TYPE,
} from 'app/redux-base/actions/configurator'
import PreviewType from 'app/redux-base/enums/PreviewType'
import { DELETE_WHITELABEL } from 'app/redux-base/actions/deleteWL'

export const initialState = {
  appVersionModel: {},
  previewType: PreviewType.SITE,
  wlConfig: null,
  loading: {},
  emails: {
    templates: [],
    renders: [],
  },
}

export default function configurator(state = initialState, action = {}) {
  switch (action.type) {
    case SWITCH_PREVIEW_TYPE:
      return {
        ...state,
        previewType: action.previewType,
      }

    // -----load email templates
    case LOAD_EMAIL_TEMPLATES.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          emails: true,
        },
      }

    case LOAD_EMAIL_TEMPLATES.SUCCESS:
      return {
        ...state,
        emails: {
          renders: [],
          templates: action.emails,
        },
        loading: {
          ...state.loading,
          emails: false,
        },
      }

    // -----load latest app version

    case LOAD_LATEST_APP_VERSION.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          appVersion: true,
        },
      }

    case LOAD_LATEST_APP_VERSION.SUCCESS:
      return {
        ...state,
        categories: action.categories,
        appVersionModel: action.response.data,
        loading: {
          ...state.loading,
          appVersion: false,
        },
        error: null,
      }

    case LOAD_LATEST_APP_VERSION.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          appVersion: false,
        },
        error: action.error,
      }

    // -----WL preview

    case UPDATE_WHITELABEL_PREVIEW:
      return {
        ...state,
        appConfig: action.appConfig,
        themeCss: action.themeCss,
        emails: {
          ...state.emails,
          renders: action.emails,
        },
      }

    // -----Publish WL

    case PUBLISH_WHITELABEL.REQUEST:
      return {
        ...state,
        loading: { publish: true },
      }

    case PUBLISH_WHITELABEL.SUCCESS:
      return {
        ...state,
        loading: { publish: false },
        error: null,
      }

    case PUBLISH_WHITELABEL.FAILURE:
      return {
        ...state,
        loading: { publish: false },
        error: action.error,
      }

    // -----Save WL

    case SAVE_WHITELABEL.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          save: true,
        },
      }

    case SAVE_WHITELABEL.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          save: false,
        },
        error: null,
      }

    case SAVE_WHITELABEL.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          save: false,
        },
        error: action.error,
      }

    // -----Delete WL

    case DELETE_WHITELABEL.REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          remove: true,
        },
      }

    case DELETE_WHITELABEL.SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          remove: false,
        },
        error: null,
      }

    case DELETE_WHITELABEL.FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          remove: false,
        },
        error: action.error,
      }

    // -----load WL

    case LOAD_WHITELABEL.REQUEST:
      return {
        ...state,
        loading: { whitelabel: action.payload.showLoading },
      }

    case LOAD_WHITELABEL.SUCCESS:
      return {
        ...state,
        wlConfig: action.wlConfig,
        originalWlConfig: action.originalWlConfig,
        loading: { whitelabel: false },
        error: null,
      }

    case LOAD_WHITELABEL.FAILURE:
      return {
        ...state,
        loading: { whitelabel: false },
        error: action.error,
      }

    case RESTORE_WHITELABEL:
      return {
        ...state,
        wlConfig: null,
        hasUnsavedChanges: false,
      }

    case SET_CONFIGURATION_CONDITION:
      return {
        ...state,
        hasUnsavedChanges: action.hasChanges,
      }

    default:
      return state
  }
}
