/* eslint-disable */
const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* eslint-enable */

const validate = ({ email, password }) => {
  const errors = {}

  if (!email) {
    errors.email = 'Required'
  } else if (!EMAIL_REGEXP.test(email)) {
    errors.email = 'Invalid email'
  }

  if (!password) {
    errors.password = 'Required'
  }

  return errors
}

export default validate
