import has from 'lodash/has'
import { isPromise } from 'app/utils/redux'

export default function promiseMiddleware({ dispatch }) {
  return next => action => {
    if (!has(action, 'payload')) {
      return isPromise(action) ? action.then(dispatch) : next(action)
    }

    if (!isPromise(action.payload)) return next(action)

    next(action)

    action.payload.then(
      result =>
        dispatch({
          ...action,
          payload: result,
        }),
      error =>
        dispatch({
          ...action,
          payload: error,
          error: true,
        })
    )

    return action.payload
  }
}
