import React from 'react'

import style from './style.module.css'

export class AccordionItemContent extends React.Component {
  componentDidMount() {
    this.styles = {}
    this.recalculateHeight(false)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      this.recalculateHeight()
    }
  }

  recalculateHeight(animate = true) {
    const measureElem = this.measure
    const contElem = this.cont
    const { opened } = this.props
    const oldStyles = { ...this.styles }
    const newStyles = { ...this.styles }

    // Set styles in object for rerendering
    if (opened) {
      newStyles.height = `${measureElem.offsetHeight}px`
    } else {
      newStyles.height = '0px'
    }

    const shouldAnimate = animate && oldStyles.height !== newStyles.height
    newStyles.overflow = shouldAnimate || !opened ? 'hidden' : 'visible'
    newStyles.transition = shouldAnimate ? 'height 0.3s ease' : 'none'
    this.styles = newStyles

    // Clear all animation related timers
    clearTimeout(this.rerenderTimeout)
    clearTimeout(this.overflowTimeout)

    // Set actual height to animate
    if (shouldAnimate && !opened) {
      contElem.style.transition = 'none'
      contElem.style.height = `${measureElem.offsetHeight}px`
      this.rerenderTimeout = setTimeout(this.animateContentHeight, 10)
    } else {
      this.animateContentHeight()
    }
  }

  animateContentHeight = () => {
    const contElem = this.cont
    const manuallyUpdateStyles = () => {
      contElem.style.transition = this.styles.transition
      contElem.style.overflow = this.styles.overflow
      contElem.style.height = this.styles.height
    }

    manuallyUpdateStyles()

    // Remove 'overflow: hidden' after end of open animation
    // and set height to auto
    this.overflowTimeout = setTimeout(() => {
      if (this.props.opened) {
        this.styles = {
          ...this.styles,
          overflow: 'visible',
          height: 'auto',
          transition: 'none',
        }

        manuallyUpdateStyles()
      }
    }, 310)
  }

  render() {
    const { children } = this.props

    return (
      <div
        ref={e => (this.cont = e)}
        style={this.styles}
        className={style.item__contentWrapper}
      >
        <div ref={e => (this.measure = e)}>
          <div className={style.item__content}>{children}</div>
        </div>
      </div>
    )
  }
}

// TODO: move to Flow types
// AccordionItemContent.propTypes = {
//   opened: PropTypes.bool,
//   children: PropTypes.node,
// }

export default AccordionItemContent
