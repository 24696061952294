// @flow
import * as React from 'react'
import Button from 'app/components/base/Button'

import style from './style.module.css'

// Types
type Props = {
  onClick: () => Promise<any>,
  children: React.Node,
}

// Component
const ActionButton = (props: Props) => {
  const [loading, setLoading] = React.useState(false)
  const { onClick, children } = props

  const handleClick = () => {
    setLoading(true)
    onClick()
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <Button
      className={style.actions__btn}
      size="small"
      onClick={handleClick}
      loading={loading}
    >
      {children}
    </Button>
  )
}

export default ActionButton
