import React from 'react'
import classnames from 'classnames'
import style from './style.module.css'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

/**
 * It joins the perpendicular and parallel align of the given query or class
 * into a single string separated by `-`
 * @param {object|string} align
 * @returns {object|string}
 */
export const parseAlign = align =>
  typeof align === 'string'
    ? align.split(' ').join('-')
    : mapValues(align, parseAlign)

/**
 * Appends the `-reverse` modifier to the given direction query or class
 * @param {object|string} direction
 * @returns {object|string}
 */
export const makeReverse = direction =>
  typeof direction === 'string'
    ? `${direction}-reverse`
    : mapValues(direction, makeReverse)

/**
 * Transforms human legible queries into the css class names
 * @param {string} query
 * @returns {string}
 */
export const parseQuery = query =>
  query
    .trim()
    .replace(/^>\s*/, 'gt-')
    .replace('mobile', 'xs')
    .replace('small', 'sm')
    .replace('medium', 'md')
    .replace('large', 'lg')

/**
 * Joins all parameters with a `-`
 * @returns {string}
 */
const snakeCase = (...args) => args.join('-')

export const propToClass = (prefix, prop) => {
  if (prop === undefined) return []

  return typeof prop !== 'object'
    ? [style[snakeCase(prefix, prop)]]
    : map(prop, (value, query) => {
        const className = style[snakeCase(prefix, value, parseQuery(query))]

        if (className === undefined) {
          throw new TypeError('Invalid layout')
        }

        return className
      })
}

/**
 * The <Layout /> element works as the container of many child flexboxes.
 * Its direction determines whether the children display forming rows or columns
 * across its length.
 * The align parameter is a string that determines where are the children placed
 * and aligned. Its composed in two parts; parallel and perpendicular.
 * Parallel:
 * - start (default)
 * - center
 * - end
 * - space around
 * - space between
 * Perpendicular:
 * - start
 * - center
 * - end
 * - stretch (default)
 * Note: the <Layout /> component can also act as a <Flex /> component when the
 * size parameter is passed
 *
 * Examples
 *
 * ```html
 * <Layout>
 *   <Flex /><Flex /><Flex />
 * </Layout>
 * ```
 *
 * Defaults to a row layout with 'start stretch' alignment
 *
 * ```
 * |[   ][   ][   ]|
 * |[ 1 ][ 2 ][ 3 ]|
 * |[   ][   ][   ]|
 * ```
 *
 * ---
 *
 * ```html
 * <Layout direction="column">
 *   <Flex /><Flex /><Flex />
 * </Layout>
 * ```
 *
 * ```
 * |[   1   ]|
 * |[   2   ]|
 * |[   3   ]|
 * ```
 *
 * ---
 *
 * ```html
 * <Layout align="center center">
 *   <Flex /><Flex /><Flex />
 * </Layout>
 * ```
 *
 * ```
 * |               |
 * |   [1][2][3]   |
 * |               |
 * ```
 *
 * ---
 *
 * ```html
 * <Layout align="space-between center">
 *   <Flex /><Flex /><Flex />
 * </Layout>
 * ```
 *
 * ```
 * |               |
 * | [1]  [2]  [3] |
 * |               |
 * ```
 *
 * @param {string} direction - either 'row' or 'column'
 * @param {number:object} size - the percent size of the element
 * @param {number:object} order - the order placing
 * @param {string} align - children alignment ('parallel perpendicular')
 * @param {boolean} wrap - wether to allow the flex elements to wrap around parent
 * @param {boolean} nowrap - same as wrap={false}
 * @param {boolean} fill - the layout will try to fill its parent element entirely
 * @param {boolean} reverse - reverse the order flex components inside are displayed
 * @returns {Element}
 */
export const Layout = ({
  children,
  className = '',
  direction = 'row',
  size,
  order,
  reverse = false,
  fill = false,
  align = 'start stretch',
  wrap = true,
  nowrap = false,
  ...props
}) => (
  <div
    className={classnames(
      className,
      ...propToClass('align', parseAlign(align)),
      ...propToClass('layout', reverse ? makeReverse(direction) : direction),
      ...propToClass('flex', size),
      ...propToClass('flex-order', order),
      { [style.wrap]: !nowrap && wrap, [style.fill]: fill }
    )}
    {...props}
  >
    {children}
  </div>
)

// TODO: move to Flow types
// Layout.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   order: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   size: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   direction: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   reverse: PropTypes.bool,
//   fill: PropTypes.bool,
//   align: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   wrap: PropTypes.bool,
//   nowrap: PropTypes.bool,
// }

/**
 * The <Flex /> element serves as both a column and a row, depending on its
 * <Layout /> direction.
 * Both the size and order parameters can take the form of a number or an object
 * if a media query needs to be specified.
 * The supported queries are:
 * - mobile
 * - small
 * - medium
 * - large
 * - the `>` operator, which means greater than (`> small`)
 *
 * Examples
 *
 * ```
 * // first on mobile, second on small and third on greater than small resolutions
 * <Flex order={{
 *   'mobile': 0,
 *   'small': 1,
 *   '> small': 2
 * }} />
 * // first on greater than small, second on mobile and third on small resolutions
 * <Flex order={{
 *   'mobile': 1,
 *   'small': 2,
 *   '> small': 0
 * }} />
 * // first on small, second on greater than small and third on mobile resolutions
 * <Flex order={{
 *   'mobile': 2,
 *   'small': 0,
 *   '> small': 1
 * }} />
 * ```
 *
 * ```
 * <Flex size={{
 *   'mobile': 50,
 *   'small': 30,
 *   '> small': 20
 * }} />
 * <Flex size="100">
 * ```
 * @param {number:object} size - the percent size of the element
 * @param {number:object} order - the order placing
 * @returns {Element}
 */
export const Flex = ({
  children,
  className = '',
  order,
  size = 'initial',
  ...props
}) => (
  <div
    className={classnames(
      ...propToClass('flex', size),
      ...propToClass('flex-order', order),
      className
    )}
    {...props}
  >
    {children}
  </div>
)

// TODO: move to Flow types
// Flex.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   order: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
//   size: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.object,
//   ]),
// }

/**
 * Utility for centering Layouts inside other Layouts, has the same parameters
 * as the <Layout> element, but it always fills the parent component.
 */
export const Container = ({ children, ...props }) => (
  <Layout fill className={style.container} nowrap>
    <Layout {...props} fill>
      {children}
    </Layout>
  </Layout>
)

// TODO: move to Flow types
// Container.propTypes = {
//   children: PropTypes.node,
// }
