import React from 'react'
import { Layout } from '../Layout'
import AccordionItemHeader from './AccordionItemHeader'
import AccordionItemContent from './AccordionItemContent'
import classnames from 'classnames'

import style from './style.module.css'

/**
 * Item that should be used inside `Accordion` component.
 * You should provide `label`, `icon` and `component` props
 * to propperly render the item.
 *
 * `label` [node] is a header of the item
 * `icon` [string] is an icon name from MaterialIcons
 */
export class AccordionItem extends React.Component {
  handleClickHeader = () => {
    const { onClickHeader, index } = this.props
    onClickHeader(index)
  }

  render() {
    const {
      label,
      icon,
      className,
      children,
      opened = false,
      ...props
    } = this.props

    return (
      <Layout
        nowrap
        size="noshrink"
        direction="column"
        className={classnames(className, style.item, {
          [style['item--opened']]: opened,
        })}
        {...props}
      >
        <AccordionItemHeader
          icon={icon}
          label={label}
          onClick={this.handleClickHeader}
        />
        <AccordionItemContent opened={opened}>{children}</AccordionItemContent>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// AccordionItem.propTypes = {
//   className: PropTypes.string,
//   label: PropTypes.node.isRequired,
//   opened: PropTypes.bool,
//   index: PropTypes.number,
//   icon: PropTypes.string.isRequired,
//   onClickHeader: PropTypes.func,
//   children: PropTypes.node,
// }

export default AccordionItem
