export const OPEN_PALETTE_ITEM = 'OPEN_PALETTE_ITEM'

export const CLOSE_PALETTE_ITEM = 'CLOSE_PALETTE_ITEM'

export const TOGGLE_PALETTE_ITEM = 'TOGGLE_PALETTE_ITEM'

export function openPaletteItem(item) {
  return {
    type: OPEN_PALETTE_ITEM,
    item,
  }
}

export function closePaletteItem() {
  return {
    type: CLOSE_PALETTE_ITEM,
  }
}

export function togglePaletteItem(item) {
  return {
    type: TOGGLE_PALETTE_ITEM,
    item,
  }
}
