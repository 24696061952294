import config from 'config'
import { getHistory } from 'app/history'
import { call, put } from 'redux-saga/effects'
import apiRequest from 'app/redux-base/sagas/common/apiRequest'
import NotificationType from 'app/redux-base/enums/NotificationType'
import { CREATE_WHITELABEL } from 'app/redux-base/actions/createWL'
import {
  showNotification,
  clearNotifications,
} from 'app/redux-base/actions/notifications'
import { SubmissionError } from 'redux-form'

export default function* createhWL(action) {
  yield put(clearNotifications())

  const url = `${config.BASE_URL_V2}/whitelabels/whitelabel/`
  const params = {
    name: action.payload.name,
    create_ga_id: action.payload.createGAproperty,
    create_ref: action.payload.createRef,
    partner_ref: action.payload.partnerRef,
  }

  const apiCall = apiClient => apiClient.post(url, params)
  yield apiCall

  const { response, error, errorData } = yield call(
    apiRequest,
    apiCall,
    CREATE_WHITELABEL,
    action
  )

  if (response) {
    const { id } = response.data
    const history = getHistory()
    yield call(history.push, `/configuration/${id}`)
    action.resolvePromise()
  } else {
    // show common error
    if (error) {
      yield put(
        showNotification({
          type: NotificationType.ERROR,
          title: 'Create whitelabel failed',
          message:
            error.message || 'some unknown server error, please try again',
        })
      )
    }

    // take one error for each invalid field
    const validationErrors = Object.keys(errorData || {}).reduce(
      (errors, field) => ({
        ...errors,
        [field]: errorData[field][0],
      }),
      {}
    )

    action.rejectPromise(new SubmissionError(validationErrors))
  }
}
