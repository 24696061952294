import React from 'react'
import Lightbox from 'mz-ui-kit/core/Lightbox'
import { Layout } from 'app/components/base/Layout'
import Icon from 'app/components/base/Icon'
import Button from 'app/components/base/Button'
import { confirmDeleteWL } from 'app/redux-base/actions/deleteWL'

import style from './style.module.css'

class DeleteDialog extends React.Component {
  state = {
    isOpened: false,
  }

  handleOnDeleteClick = () => {
    const { dispatch, whitelabelId } = this.props
    dispatch(confirmDeleteWL(whitelabelId, this.confirmTextDOM.value))
  }

  show = () => {
    this.setState({ isOpened: true })
  }

  hide = () => {
    this.setState({ isOpened: false })
  }

  handleInputChange = e => {
    this.confirmTextDOM.value = e.target.value
  }

  render() {
    const { loading } = this.props
    return (
      <Lightbox
        onClose={this.hide}
        showed={this.state.isOpened}
        render={() => (
          <div className={style.deleteDialogContainer}>
            <Layout
              direction="column"
              align="center center"
              className={style.deleteDialog}
            >
              <Icon className={style.deleteDialog__icon}>cancel</Icon>
              <span className={style.deleteDialog__iconLabel}>
                DELETING WHITELABEL
              </span>
              <p>
                This will permanently delete this whitelabel, and all saved
                changes. Are you sure?
              </p>
              <p className={style.deleteDialog__confirmText}>
                Type the word &ldquo;DELETE&rdquo;
              </p>
              <input
                onChange={this.handleInputChange}
                className={style.deleteDialog__input}
                ref={c => (this.confirmTextDOM = c)}
                type="text"
              />
              <Button
                size="small"
                className={style.deleteDialog__button}
                onClick={this.handleOnDeleteClick}
                loading={loading}
              >
                CONFIRM
              </Button>
            </Layout>
          </div>
        )}
      />
    )
  }
}

// TODO: move to Flow types
// DeleteDialog.propTypes = {
//   dispatch: PropTypes.func.isRequired,
//   loading: PropTypes.bool,
//   whitelabelId: PropTypes.number.isRequired,
// }

export default DeleteDialog
