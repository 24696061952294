import { call, select } from 'redux-saga/effects'
import config from 'config'
import ApiClient from 'app/utils/ApiClient'

export default function* createUploadSigner(action) {
  const { wlConfig } = action
  const url = `${config.BASE_URL_V2}/whitelabels/whitelabel/${wlConfig.id}/sign_upload/`

  const apiClient = yield new ApiClient()
  const state = yield select()

  const getTokenFunc = apiClient.getTokenFromState.bind(apiClient)
  yield getTokenFunc

  yield call(getTokenFunc, state)

  return async (type, name) => {
    const { data } = await apiClient.post(url, { type, name })
    return data
  }
}
