const validate = ({ config }, { configurable_json: configJson }) => {
  const errors = {}

  const defaultFields = configJson ? configJson.fields : []

  if (defaultFields) {
    defaultFields
      .filter(
        ({ type, editable }) =>
          (type === 'color' || type === 'string') && editable !== false
      )
      .forEach(({ name }) => {
        if (config && !config[name]) {
          errors[name] = 'Required'
        }
      })
  }

  return {
    config: errors,
  }
}

export default validate
