import React from 'react'
import classnames from 'classnames'

import style from './style.module.css'
import logoPrimary from 'img/logo.svg'
import logoInverted from 'img/logo_white.svg'

/**
 * If logoSrc provided then returns logoSrc. Otherwise return
 * mozio default logo accorging to given `inverted` property
 * @param  {String} logoSrc
 * @param  {Boolean} inverted
 * @return {String}
 */
export const getLogoSrc = (logoSrc, inverted) => {
  if (logoSrc) {
    return logoSrc
  }
  return inverted ? logoInverted : logoPrimary
}

/**
 * Show logo with given src. If href provided then logo
 * will be a link to some page. If src is not provided then it
 * uses default mozio logo according to given `inverted` property
 * @param  {String} inverted  show dark (inverted = false) or white logo
 * @param  {String} href  href for logo link
 * @param  {String} src   logo soruce url
 * @return {Component}
 */
export const Logo = ({ href, inverted = false, src, className, ...props }) => (
  <div className={classnames(className, style.logo)} {...props}>
    <a className={style.logo__link} href={href} nohref={!!href}>
      <img
        src={getLogoSrc(src, inverted)}
        alt="Mozio logo"
        className={style.logo__image}
      />
    </a>
  </div>
)

// TODO: move to Flow types
// Logo.propTypes = {
//   href: PropTypes.string,
//   inverted: PropTypes.bool,
//   src: PropTypes.string,
//   className: PropTypes.string,
// }

export default Logo
