import { combineReducers } from 'redux'

import { reducer as formReducer } from 'redux-form'
import auth from './auth'
import create from './create'
import notifications from './notifications'
import configurator from './configurator'
import whitelabelsList from './whitelabelsList'
import palette from './palette'

export default combineReducers({
  form: formReducer,
  auth,
  create,
  notifications,
  configurator,
  whitelabelsList,
  palette,
})
