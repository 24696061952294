import React from 'react'
import Switch from 'app/components/base/Switch'
import FieldBase from '../../commons/FieldBase'

export const BoolRenderer = ({ field, ...props }) => (
  <FieldBase label={field.description} align="start center">
    <Switch
      {...props}
      // avoid warning because redux-form inits boolean fields with ''
      value={!!props.input.value}
      noValueLabel
    />
  </FieldBase>
)

// TODO: move to Flow types
// BoolRenderer.propTypes = {
//   field: PropTypes.object,
//   input: PropTypes.object,
// }

export default BoolRenderer
