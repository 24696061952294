import config from 'config'
import { call, put, select } from 'redux-saga/effects'
import apiRequest from 'app/redux-base/sagas/common/apiRequest'
import loadLatestAppModel from '../loadLatestAppModel'
import loadEmailTemplates from '../loadEmailTemplates'
import { getAppVersionModel } from 'app/redux-base/selectors/configurator'
import { defaultValues } from 'app/redux-base/customizationUtils'
import {
  loadWhitelabelSuccess,
  loadWhitelabelFailure,
} from 'app/redux-base/actions/configurator'
import _cloneDeep from 'lodash/cloneDeep'
import updatePreviewConfigs from '../watchUpdatePreview/updatePreviewConfigs'

export default function* loadWL(action) {
  const {
    payload: { id },
  } = action
  // TODO: wait for success load of app model
  yield call(loadLatestAppModel)
  yield call(loadEmailTemplates, id)

  const loadWlUrl = `${config.BASE_URL_V2}/whitelabels/whitelabel/${id}/`
  const loadWlApiCall = apiClient => apiClient.get(loadWlUrl)
  yield loadWlApiCall

  const { error, response } = yield call(apiRequest, loadWlApiCall)
  if (response) {
    const {
      configurable_json: { fields: defaultFields },
    } = yield select(getAppVersionModel)

    const wlConfig = {
      ...response.data,
      config: {
        ...defaultValues(defaultFields),
        ...response.data.config,
      },
    }

    const originalWlConfig = _cloneDeep(wlConfig)

    yield put(loadWhitelabelSuccess(id, wlConfig, originalWlConfig))
    yield call(updatePreviewConfigs, wlConfig.config)
  } else {
    yield put(loadWhitelabelFailure(id, error.message))
  }
}
