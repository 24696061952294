import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import { Layout, Flex } from 'app/components/base/Layout'
import validate from './form/validate'
import Navigation from 'app/components/containers/Navigation'
import Footer from 'app/components/base/Footer'
import { loginRequest } from 'app/redux-base/actions/loginFlow'
import Checkbox from 'mz-ui-kit/form/CheckboxField'
import Button from 'mz-ui-kit/core/Button'
import onlyNotLogged from 'app/components/HOCs/onlyNotLogged'
import TextField from 'mz-ui-kit/form/TextField'

import style from './style.module.css'

const FormTitle = () => <div className={style.title}>Login to your account</div>

const LoginForm = ({ onSubmit, loading }) => (
  <Layout className={style.form} direction="column" nowrap>
    <LoginFields />
    <Flex>
      <Button
        fullWidth
        size="large"
        color="yellow"
        loading={loading}
        onClick={onSubmit}
      >
        Sign In
      </Button>
    </Flex>
    <CreateForgotLine />
  </Layout>
)

// TODO: move to Flow types
// LoginForm.propTypes = {
//   loading: PropTypes.bool,
//   onSubmit: PropTypes.func.isRequired,
// }

const LoginFields = () => (
  <Layout direction="column">
    <Flex className={style.form__email}>
      <Field name="email" label="Email" component={TextField} />
    </Flex>
    <Flex className={style.form__password}>
      <Field
        name="password"
        label="Password"
        component={TextField}
        type="password"
      />
    </Flex>
    <Flex className={style.form__remember}>
      <Field name="rememberMe" component={Checkbox} label="Remember me" />
    </Flex>
  </Layout>
)

const CreateForgotLine = () => (
  <Layout align="space-between center" className={style.links}>
    <div>
      <span className={style.links__label}>Don&apos;t have an account?</span>
      <Link className={style.links__link} to="/user/register">
        Create one
      </Link>
    </div>
    <Link className={style.links__link} to="/user/forgotPassword">
      Forgot email or password?
    </Link>
  </Layout>
)

export class LoginPage extends Component {
  onSubmit = ({ email, password, rememberMe }) => {
    this.props.dispatch(loginRequest(email, password, rememberMe))
  }

  render() {
    const { handleSubmit, loading } = this.props

    return (
      <Layout fill nowrap direction="column">
        <Flex size="noshrink">
          <Navigation />
        </Flex>
        <Layout
          size="grow"
          className={style.background}
          align="center center"
          direction="column"
        >
          <div>
            <FormTitle />
            <LoginForm
              loading={loading}
              onSubmit={handleSubmit(this.onSubmit)}
            />
          </div>
        </Layout>
        <Flex size="noshrink">
          <Footer />
        </Flex>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// LoginPage.propTypes = {
//   loading: PropTypes.bool,
//   dispatch: PropTypes.func.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
// }

const mapStateToProps = state => ({ loading: state.auth.loading })

export default compose(
  onlyNotLogged(),
  reduxForm({
    form: 'loginForm',
    validate,
  }),
  connect(mapStateToProps)
)(LoginPage)
