import { call, put, select } from 'redux-saga/effects'

import ApiClient from 'app/utils/ApiClient'

export default function* apiRequest(promise, baseAction, action) {
  const apiClient = yield new ApiClient()

  const state = yield select()

  const getTokenFunc = apiClient.getTokenFromState.bind(apiClient)
  yield getTokenFunc

  // inject token from state
  yield call(getTokenFunc, state)

  try {
    const response = yield call(promise, apiClient)

    if (baseAction) {
      yield put({ ...action, response, type: baseAction.SUCCESS })
    }

    return { response }
  } catch (error) {
    const isServerError = error.isAxiosError
    const errorMessage = isServerError
      ? error.response.data.message
      : error.message

    if (baseAction) {
      yield put({ ...action, error: errorMessage, type: baseAction.FAILURE })
    }

    let errorData = null
    if (isServerError && error.response.status === 400) {
      errorData = error.response.data
    }

    return { error: new Error(errorMessage), errorData }
  }
}
