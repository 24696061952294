import config from 'config'
import _ from 'lodash'
import { call, put } from 'redux-saga/effects'
import apiRequest from 'app/redux-base/sagas/common/apiRequest'
import {
  LOAD_LATEST_APP_VERSION,
  loadLatestAppVersionRequest,
} from 'app/redux-base/actions/configurator'

export const categorizeFields = ({ fields }) => {
  const onlyEditable = _.filter(fields, f => f.editable !== false)
  const grouped = _.groupBy(onlyEditable, f => f.category)
  return _.toPairs(grouped).map(x => ({ name: x[0], fields: x[1] }))
}

export default function* loadLatestAppModel() {
  // wait for load of latest app version
  yield put(loadLatestAppVersionRequest())

  const loadLatestVersionUrl = `${config.BASE_URL_V2}/whitelabels/version/latest/`
  const loadLatestVersionApiCall = apiClient =>
    apiClient.get(loadLatestVersionUrl)
  yield loadLatestVersionApiCall

  const response = yield call(apiRequest, loadLatestVersionApiCall)
  if (!response.error) {
    const confJson = response.response.data.configurable_json
    const categories = yield call(categorizeFields, confJson)
    yield put({
      categories,
      response: response.response,
      type: LOAD_LATEST_APP_VERSION.SUCCESS,
    })
  }

  return response
}
