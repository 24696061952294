import React from 'react'
import uniqueId from 'lodash/uniqueId'
import classnames from 'classnames'

import style from './style.module.css'

class Switch extends React.Component {
  constructor() {
    super()
    this.id = uniqueId()
  }

  handleOnChange = e => {
    const value = e.target.checked
    if (this.props.input.onChange) {
      this.props.input.onChange(value)
    }
  }

  render() {
    const {
      input: { value },
      onLabel,
      offLabel,
      noValueLabel,
      toggleOnLabel = 'on',
      toggleOffLabel = 'off',
      onColor = '#2F6FA8',
      offColor = 'rgb(217, 217, 217)',
    } = this.props

    return (
      <div>
        <label
          htmlFor={this.id}
          className={classnames(style.label, { [style.on]: value })}
          onClick={e => e.stopPropagation()}
        >
          <input
            type="checkbox"
            id={this.id}
            checked={value}
            className={style.input}
            onChange={this.handleOnChange}
          />
          <div
            className={style.switchBodyWrapper}
            style={{
              backgroundColor: value ? onColor : offColor,
            }}
          >
            <div className={style.switchBody}>
              <div className={style.leftLabel}>
                <span className={style.leftLabel_onText}>{toggleOnLabel}</span>
                <span className={style.leftLabel_offText}>
                  {toggleOffLabel}
                </span>
              </div>
              <div className={style.toggle}></div>
              <div className={style.rightLabel}>
                <span className={style.rightLabel_onText}>{toggleOnLabel}</span>
                <span className={style.rightLabel_offText}>
                  {toggleOffLabel}
                </span>
              </div>
            </div>
          </div>
          {!noValueLabel && (
            <span className={style.valueLabel}>
              {value ? onLabel : offLabel}
            </span>
          )}
        </label>
      </div>
    )
  }
}

// TODO: move to Flow types
// Switch.propTypes = {
//   onLabel: PropTypes.node,
//   offLabel: PropTypes.node,
//   toggleOnLabel: PropTypes.node,
//   toggleOffLabel: PropTypes.node,
//   onColor: PropTypes.string,
//   offColor: PropTypes.string,
//   noValueLabel: PropTypes.bool,
//   // redux-form field props
//   input: PropTypes.shape({
//     value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//     onChange: PropTypes.func.isRequired,
//   }),
// }

export default Switch
