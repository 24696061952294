import { takeLatest } from 'redux-saga/effects'
import { actionTypes } from 'redux-form'
import { CONFIGURATION_FORM_NAME } from 'app/constants'
import updatePreview from './updatePreview'

export default function* watchUpdatePreview() {
  const configuratorActionPattern = yield action =>
    action.type === actionTypes.CHANGE &&
    action.meta.form === CONFIGURATION_FORM_NAME

  yield takeLatest(configuratorActionPattern, updatePreview)
}
