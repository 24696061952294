import React from 'react'
import classnames from 'classnames'

import style from './style.module.css'

// Size params
const SIZES = {
  normal: 'normal',
  small: 'small',
  large: 'large',
}
const SVG_SIZES = {
  [SIZES.normal]: { width: '30', height: '30' },
  [SIZES.small]: { width: '20', height: '20' },
  [SIZES.large]: { width: '50', height: '50' },
}
const CIRCLE_SIZES = {
  [SIZES.normal]: { cx: '15', cy: '15', r: '13' },
  [SIZES.small]: { cx: '10', cy: '10', r: '9' },
  [SIZES.large]: { cx: '25', cy: '25', r: '23' },
}

/**
 * Loading circle component. Can be with three
 * sizes: small, normal and large
 * @param  {String} size      size of the circle
 * @return {Component}
 */
const Loading = ({ className, size = 'normal', ...props }) => (
  <span
    className={classnames(className, style.container, style[`size-${size}`])}
    {...props}
  >
    <svg className={style.circular} {...SVG_SIZES[size]}>
      <circle
        {...CIRCLE_SIZES[size]}
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
        className={style.path}
      />
    </svg>
  </span>
)

// TODO: move to Flow types
// Loading.propTypes = {
//   className: PropTypes.string,
//   size: PropTypes.oneOf(Object.keys(SIZES)),
// }

export default Loading
