// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'

import './style/modules/_typography.css'
import './style/vendor/_normalize.css'
import './style/vendor/_material.css'

const container = document.getElementById('root')
if (container) {
  ReactDOM.render(<App />, container)
} else {
  throw new Error('There is no container available for React app')
}
