import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import userSelector from 'app/redux-base/selectors/user'

export default function checkAuth(options = {}) {
  return Wrapped => {
    class AuthenticatedComponent extends Component {
      componentDidMount() {
        this.checkAndRedirect()
      }

      checkAndRedirect() {
        const { location, user, history } = this.props

        const redirectPath = options.redirectTo || '/dashboard'

        if (
          user &&
          location &&
          ['/login', '/signup'].includes(location.pathname)
        ) {
          history.replace(redirectPath)
        }
      }

      render() {
        return <Wrapped {...this.props} />
      }
    }

    // TODO: move to Flow types
    // AuthenticatedComponent.propTypes = {
    //   user: PropTypes.object,
    //   location: PropTypes.object,
    // }

    const mapStateToProps = state => userSelector(state)
    return withRouter(connect(mapStateToProps)(AuthenticatedComponent))
  }
}
