import React from 'react'
import _ from 'lodash'

export const SelectField = ({ input, options = [] }) => {
  const { value, onChange } = input
  return (
    <select value={value} onChange={onChange}>
      {options.map((o, index) => (
        <option key={index} value={_.isPlainObject(o) ? o.value : o}>
          {_.isPlainObject(o) ? o.title : o}
        </option>
      ))}
    </select>
  )
}

// TODO: move to Flow types
// SelectField.propTypes = {
//   input: PropTypes.object.isRequired,
//   options: PropTypes.array.isRequired,
// }

export default SelectField
