import { call, put, select } from 'redux-saga/effects'
import {
  getAppVersionModel,
  getEmails,
} from 'app/redux-base/selectors/configurator'
import { updateWhitelabelPreview } from 'app/redux-base/actions/configurator'
import { getThemeCss, renderEmails } from 'app/redux-base/customizationUtils'

export default function* updatePreviewConfigs(wlConfig) {
  const { theme_css } = yield select(getAppVersionModel)
  const { templates } = yield select(getEmails)

  // TODO: add validation for incorrect colors etc.
  try {
    const generatedCss = yield call(getThemeCss, theme_css, wlConfig)
    const generatedEmails = yield call(renderEmails, templates, wlConfig)
    yield put(updateWhitelabelPreview(wlConfig, generatedCss, generatedEmails))
  } catch (err) {
    console.log(err) // eslint-disable-line
  }
}
