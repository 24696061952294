import config from 'config'

import { call, put } from 'redux-saga/effects'
import apiRequest from '../../common/apiRequest'
import { getHistory } from 'app/history'
import {
  DELETE_WHITELABEL,
  deleteWhitelabelRequest,
} from 'app/redux-base/actions/deleteWL'
import NotificationType from 'app/redux-base/enums/NotificationType'
import {
  showNotification,
  clearNotifications,
} from 'app/redux-base/actions/notifications'

export default function* confirmDeleteWL(action) {
  const { id, confirmText } = action

  if (confirmText !== 'DELETE') {
    return
  }

  yield put(clearNotifications())

  yield put(deleteWhitelabelRequest(id))

  const url = `${config.BASE_URL_V2}/whitelabels/whitelabel/${id}/`

  const apiCall = apiClient => apiClient.delete(url)
  yield apiCall

  const { response, error } = yield call(
    apiRequest,
    apiCall,
    DELETE_WHITELABEL,
    action
  )

  if (response) {
    const history = getHistory()
    yield call(history.replace, '/dashboard')
  } else {
    yield put(
      showNotification({
        type: NotificationType.ERROR,
        title: 'Delete whitelabel failed',
        message: error.message,
      })
    )
  }
}
