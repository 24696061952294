import createRequestTypes from 'app/utils/createRequestTypes'

export const LOGIN = createRequestTypes('LOGIN')
export const LOGOUT = 'LOGOUT'

export function loginRequest(email, password, rememberMe) {
  return {
    type: LOGIN.REQUEST,
    email,
    password,
    rememberMe,
  }
}

export function loginSuccess(user) {
  return {
    type: LOGIN.SUCCESS,
    user,
  }
}

export function loginFailure(error) {
  return {
    type: LOGIN.FAILURE,
    error,
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}
