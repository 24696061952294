import React from 'react'
import { Layout, Flex } from '../Layout'
import Icon from '../Icon'

import style from './style.module.css'

export const AccordionItemHeader = ({ onClick, label, icon }) => (
  <Layout
    nowrap
    size="noshrink"
    direction="row"
    align="start center"
    className={style.item__header}
    onClick={onClick}
  >
    <Flex className={style.item__icon} size="noshrink">
      <Icon>{icon}</Icon>
    </Flex>
    <Flex className={style.item__label} size="grow">
      {label}
    </Flex>
    <span className={style.item__arrow} />
  </Layout>
)

// TODO: move to Flow types
// AccordionItemHeader.propTypes = {
//   label: PropTypes.node,
//   icon: PropTypes.string,
//   onClick: PropTypes.func,
// }

export default AccordionItemHeader
