import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CREATE_WHITELABE_FORM_NAME } from 'app/constants'
import Navigation from 'app/components/containers/Navigation'
import { withRouter } from 'react-router'
import { Layout, Flex } from 'app/components/base/Layout'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import CreateForm from './CreateForm'
import { createWhitelabelRequest } from 'app/redux-base/actions/createWL'
import requireAuth from 'app/components/HOCs/requireAuth'
import validate from './form/validate'

import style from './style.module.css'

export class CreatePage extends Component {
  onSubmit = ({ name, createGAproperty, createRef, partnerRef }) => {
    const { promise, ...action } = createWhitelabelRequest({
      name,
      createGAproperty,
      createRef,
      partnerRef,
    })
    this.props.dispatch(action)
    // for server-side validation errors
    return promise
  }

  onCancel = () => {
    const { history } = this.props
    history.push('/dashboard')
  }

  render() {
    const { handleSubmit, loading } = this.props

    return (
      <Layout fill nowrap direction="column" className={style.container}>
        <Flex size="noshrink">
          <Navigation />
        </Flex>
        <Flex size="grow" className={style.workspace}>
          <Layout direction="row" nowrap align="center center">
            <CreateForm
              loading={loading}
              onSubmit={handleSubmit(this.onSubmit)}
              onCancel={this.onCancel}
            />
          </Layout>
        </Flex>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// CreatePage.propTypes = {
//   loading: PropTypes.bool,
//   dispatch: PropTypes.func.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
// }

const mapStateToProps = state => ({ loading: state.create.loading })

export default compose(
  withRouter,
  requireAuth(),
  reduxForm({
    form: CREATE_WHITELABE_FORM_NAME,
    validate,
    initialValues: {
      createRef: true,
    },
  }),
  connect(mapStateToProps)
)(CreatePage)
