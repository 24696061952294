import React from 'react'
import FieldBase from '../../commons/FieldBase'

export const UnknownRenderer = ({ field }) => (
  <FieldBase>unknown field type {field.type}</FieldBase>
)

// TODO: move to Flow types
// UnknownRenderer.propTypes = {
//   field: PropTypes.object,
// }

export default UnknownRenderer
