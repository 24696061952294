/* eslint-disable max-classes-per-file */
import React from 'react'
import _ from 'lodash'
import { Layout, Flex } from 'app/components/base/Layout'
import Button from 'app/components/base/Button'
import TextField from 'app/components/base/TextField'
import FieldBase from '../../commons/FieldBase'
import style from './style.module.css'

// For showing one input in pair
export class PairInput extends React.Component {
  handleChange = e => {
    this.props.onChange(this.props.index, e)
  }

  render() {
    const { value } = this.props
    return (
      <TextField
        rectangle
        wide
        input={{ value, onChange: this.handleChange }}
      />
    )
  }
}

// TODO: move to Flow types
// PairInput.propTypes = {
//   value: PropTypes.string.isRequired,
//   index: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired,
// }

// For showing one pair
export class PairItem extends React.Component {
  handleRemove = () => {
    this.props.onRemove(this.props.index)
  }

  handleChangeInput = (j, e) => {
    this.props.onChange(this.props.index, j, e)
  }

  render() {
    const { pair } = this.props
    return (
      <Layout
        align="center center"
        className={style.item}
        size="noshrink"
        nowrap
      >
        <Flex size="noshrink">
          <Button
            className={style.item__button}
            onClick={this.handleRemove}
            color="clear"
            icon="remove"
            size="small"
            compact
          />
        </Flex>
        <Flex className={style.item__input} size="grow">
          <PairInput
            index={0}
            value={pair[0]}
            onChange={this.handleChangeInput}
          />
        </Flex>
        <Flex size="grow">
          <PairInput
            index={1}
            value={pair[1]}
            onChange={this.handleChangeInput}
          />
        </Flex>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// PairItem.propTypes = {
//   pair: PropTypes.array.isRequired,
//   index: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired,
//   onRemove: PropTypes.func.isRequired,
// }

/**
 * Component for rendering editable pairs.
 */
export class PairsRenderer extends React.Component {
  handleAddLink = () => {
    const {
      input: { onChange, value },
    } = this.props
    const nextValue = [].concat(value || [], [['', '']])
    onChange(nextValue)
  }

  handleChangePair = (i, j, e) => {
    const {
      input: { onChange, value },
    } = this.props
    const nextValue = _.cloneDeep(value)
    nextValue[i][j] = e.target.value
    onChange(nextValue)
  }

  handleRemovePair = i => {
    const {
      input: { onChange, value },
    } = this.props
    const nextValue = _.cloneDeep(value)
    nextValue.splice(i, 1)
    onChange(nextValue)
  }

  render() {
    const {
      field,
      input: { value },
    } = this.props
    return (
      <FieldBase
        label={field.description}
        direction="column"
        align="start start"
      >
        {!!value &&
          value.map((p, i) => (
            <PairItem
              key={i}
              pair={p}
              index={i}
              onRemove={this.handleRemovePair}
              onChange={this.handleChangePair}
            />
          ))}
        <Flex>
          <Button
            className={style.block__addButton}
            onClick={this.handleAddLink}
            color="clear"
            icon="add"
            size="small"
            compact
          >
            Add
          </Button>
        </Flex>
      </FieldBase>
    )
  }
}

// TODO: move to Flow types
// PairsRenderer.propTypes = {
//   field: PropTypes.object.isRequired,
//   input: PropTypes.object.isRequired,
// }

export default PairsRenderer
