import {
  OPEN_PALETTE_ITEM,
  CLOSE_PALETTE_ITEM,
  TOGGLE_PALETTE_ITEM,
} from 'app/redux-base/actions/palette'

export const initialState = {
  openedItem: null,
}

export default function palette(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_PALETTE_ITEM:
      return { openedItem: action.item }

    case CLOSE_PALETTE_ITEM:
      return { openedItem: null }

    case TOGGLE_PALETTE_ITEM:
      return {
        openedItem: state.openedItem === action.item ? null : action.item,
      }

    default:
      return state
  }
}
