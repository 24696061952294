import React from 'react'
import { Layout, Flex, Container } from 'app/components/base/Layout'
import classnames from 'classnames'
import style from './style.module.css'
import blankImg from 'img/blank.gif'

export const EditButton = props => (
  <Flex className={style.buttons__item} {...props}>
    <i className={classnames('material-icons', style.buttons__icon)}>edit</i>
    Edit
  </Flex>
)

export const PreviewButton = ({ offline, ...props }) => (
  <Flex
    className={classnames(style.buttons__item, {
      [style['buttons__item--disabled']]: offline,
    })}
    {...props}
  >
    <i className={classnames('material-icons', style.buttons__icon)}>
      visibility
    </i>
    Preview
  </Flex>
)

// TODO: move to Flow types
// PreviewButton.propTypes = {
//   offline: PropTypes.bool,
// }

export const CreateButton = props => (
  <Container fill align="center center" className={style.create} {...props}>
    <Flex>
      <div>Create new whitelabel</div>
      <i className="material-icons">add_circle</i>
    </Flex>
  </Container>
)

export const WhitelabelGridItem = ({
  name,
  thumbnail = blankImg,
  offline = false,
  create = false,
  onClickCreate,
  onClickPreview,
  onClickEdit,
  className,
  ...props
}) => (
  <Layout
    direction="column"
    className={classnames(style.container, className, {
      [style['container--create']]: create,
    })}
    {...props}
  >
    <Flex className={style.header}>
      <span className={style.header__name}>{name}</span>
      <span
        className={classnames(style.header__online, {
          [style['header__online--off']]: offline,
        })}
      />
    </Flex>
    <Flex className={style.screenshot}>
      {create ? (
        <CreateButton onClick={onClickCreate} />
      ) : (
        <img
          src={thumbnail}
          className={style.screenshot__image}
          alt={`${name} thumbnail`}
        />
      )}
    </Flex>
    <Layout direction="row" nogrow className={style.buttons}>
      <PreviewButton onClick={!offline && onClickPreview} offline={offline} />
      <EditButton onClick={onClickEdit} />
    </Layout>
  </Layout>
)

// TODO: move to Flow types
// WhitelabelGridItem.propTypes = {
//   name: PropTypes.string,
//   thumbnail: PropTypes.string,
//   className: PropTypes.string,
//   onClickPreview: PropTypes.func,
//   onClickEdit: PropTypes.func,
//   onClickCreate: PropTypes.func,
//   offline: PropTypes.bool,
//   create: PropTypes.bool,
// }

export default WhitelabelGridItem
