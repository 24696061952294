/* @flow */
import React from 'react'
import { Provider } from 'react-redux'
import configureStore from './redux-base/configureStore'
import Routes from './pages/Routes'
import { Provider as StyletronProvider } from 'styletron-react'
import { Client as StyletronClient } from 'styletron-engine-atomic'

const store = configureStore({})
const styletronClient = new StyletronClient()

const App = () => (
  <StyletronProvider value={styletronClient}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </StyletronProvider>
)

export default App
