import React from 'react'
import { compose } from 'redux'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import { Layout, Flex } from 'app/components/base/Layout'
import Loading from 'app/components/base/Loading'
import Button from 'app/components/base/Button'
import SwitchButton from 'app/components/base/SwitchButton'
import * as Accordion from 'app/components/base/Accordion'
import FieldRenderer from 'app/components/configuration/FieldRenderer'
import DeleteDialog from './DeleteDialog'
import CloneDialog from './CloneDialog'
import ActionButton from './ActionButton'
import {
  getLoadingStatus,
  getWlConfig,
  getFieldCategories,
} from 'app/redux-base/selectors/configurator'
import { CONFIGURATION_FORM_NAME } from 'app/constants'
import {
  publishWhitelabelRequest,
  loadWhitelabelRequest,
  restoreWhitelabel,
  saveWhitelabelRequest,
  createGaProperty,
} from 'app/redux-base/actions/configurator'
import validate from './form/validate'

import style from './style.module.css'

// Constants
const DEFAULT_OPENED_ITEMS = [0, 1]
const CATEGORY_DATA = {
  analytics: { icon: 'timeline', label: 'Analytics' },
  palette: { icon: 'palette', label: 'Color palette' },
  backgrounds: { icon: 'photo_library', label: 'Backgrounds' },
  header: { icon: 'photo_library', label: 'Header' },
  footer: { icon: 'photo_library', label: 'Footer' },
}

const LiveBtnField = field => (
  <SwitchButton
    {...field}
    // avoid warning because redux-form inits boolean fields with ''
    value={!!field.input.value}
  />
)

export const DeleteLink = props => (
  <span className={style.deleteLink} {...props}>
    Delete whitelabel?
  </span>
)

const LoadingContainer = () => {
  return (
    <Layout direction="column" align="center cetner" nowrap size="noshrink">
      <Flex className={style.loading}>
        <Loading size="large" />
      </Flex>
    </Layout>
  )
}

export class ConfiguratorContainer extends React.Component {
  UNSAFE_componentWillMount() {
    const { whitelabelId } = this.props
    this.props.dispatch(loadWhitelabelRequest({ id: whitelabelId }))
  }

  componentWillUnmount() {
    this.props.dispatch(restoreWhitelabel())
  }

  handleCreateGaProperty = () => {
    const { initialValues: wlConfig } = this.props

    const { promise, ...action } = createGaProperty(wlConfig.id)
    this.props.dispatch(action)

    return promise
  }

  handleSave = config => {
    const { promise, ...action } = saveWhitelabelRequest(config)
    this.props.dispatch(action)
    // for server-side validation errors
    return promise
  }

  handleCloneWL = () => {
    this.cloneDialog.show()
  }

  handleOnClickDelete = () => {
    this.deleteDialog.show()
  }

  handleOnPublish = isLive => {
    this.props.dispatch(publishWhitelabelRequest(isLive))
  }

  renderAccordion() {
    const { loading, handleSubmit, initialValues, categories = [] } = this.props

    return (
      <div>
        <div className={style.whitelabelName}>
          {initialValues ? `${initialValues.name}.mozio.com` : 'Loading...'}
        </div>
        <Accordion.Container
          className={style.accordion}
          defaultOpened={DEFAULT_OPENED_ITEMS}
        >
          {categories.map(category => (
            <Accordion.Item
              key={category.name}
              icon={_.get(
                CATEGORY_DATA[category.name],
                'icon',
                'photo_library'
              )}
              label={_.get(
                CATEGORY_DATA[category.name],
                'label',
                category.name
              )}
            >
              <div className={style.accordionItem}>
                {category.fields.map(field => (
                  <FieldRenderer field={field} key={field.name} />
                ))}
              </div>
            </Accordion.Item>
          ))}
        </Accordion.Container>
        <Layout
          direction="column"
          nowrap
          size="noshrink"
          className={style.actions}
        >
          <span className={style.actions__header}>Actions</span>
          <ActionButton onClick={this.handleCreateGaProperty}>
            Create GA property for the WL
          </ActionButton>
          <Button
            className={style.actions__btn}
            size="small"
            onClick={this.handleCloneWL}
          >
            Clone WL
          </Button>
        </Layout>
        <Layout
          key="2"
          nowrap
          size="noshrink"
          direction="row"
          align="space-between center"
          className={style.actionsBlock}
        >
          <Field
            name="is_live"
            component={LiveBtnField}
            label="Live"
            onChange={this.handleOnPublish}
            loading={loading.publish}
          />
          <Button
            color="secondary"
            size="small"
            loading={loading.save}
            onClick={handleSubmit(this.handleSave)}
          >
            Save Changes
          </Button>
        </Layout>
        <div>
          <DeleteLink onClick={this.handleOnClickDelete} />
        </div>
      </div>
    )
  }

  render() {
    const {
      whitelabelId,
      initialValues,
      className,
      loading,
      dispatch,
      ...props
    } = this.props

    return (
      <Layout
        nowrap
        direction="column"
        className={classnames(className, style.container)}
        {...props}
      >
        {loading.whitelabel ? <LoadingContainer /> : this.renderAccordion()}
        <DeleteDialog
          ref={c => (this.deleteDialog = c)}
          whitelabelId={whitelabelId}
          dispatch={dispatch}
          loading={loading.remove}
        />
        <CloneDialog
          ref={c => (this.cloneDialog = c)}
          whitelabelId={whitelabelId}
          dispatch={dispatch}
        />
      </Layout>
    )
  }
}

// TODO: move to Flow types
// ConfiguratorContainer.propTypes = {
//   className: PropTypes.string,
//   loading: PropTypes.object,
//   whitelabelId: PropTypes.number,
//   dispatch: PropTypes.func.isRequired,
//   handleSubmit: PropTypes.func.isRequired,
//   initialValues: PropTypes.object,
//   categories: PropTypes.array,
// }

const mapStateToProps = state => ({
  initialValues: getWlConfig(state),
  loading: getLoadingStatus(state),
  categories: getFieldCategories(state),
})

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: CONFIGURATION_FORM_NAME,
    validate,
    enableReinitialize: true,
  })
)(ConfiguratorContainer)
