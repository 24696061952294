/* eslint-disable global-require */
import React from 'react'
import { Field } from 'redux-form'

// Constants
const renderers = {
  bool: require('./renderers/bool').default,
  color: require('./renderers/color').default,
  image: require('./renderers/image').default,
  pairs: require('./renderers/pairs').default,
  select: require('./renderers/select').default,
  string: require('./renderers/string').default,
  unknown: require('./renderers/unknown').default,
}

/**
 * React component for rendering given field object
 * @param  {Object} field
 * @param  {Object} props
 * @return {Component}
 */
export const FieldRenderer = ({ field, ...props }) => {
  const Renderer = renderers[field.type] || renderers.unknown
  return (
    <Field
      name={`config.${field.name}`}
      component={Renderer}
      field={field}
      {...props}
    />
  )
}

// TODO: move to Flow types
// FieldRenderer.propTypes = {
//   field: PropTypes.object,
// }

export default FieldRenderer
