import React from 'react'
import classnames from 'classnames'

import { Layout, Flex } from 'app/components/base/Layout'
import Icon from 'app/components/base/Icon'

import style from './style.module.css'

/**
 * Notification bar component that is absolutely positioned on top
 * and shows a notification that can be of info, warning and error nature.
 */
const NotificationBar = ({ type, message, title, onClose }) => {
  return (
    !!message && (
      <Layout className={classnames(style.container, style[type])}>
        <Icon>{type}</Icon>
        <Flex className={style.message}>
          {title && <span className={style.title}>{title}</span>}{' '}
          {message && <span>{message}</span>}
        </Flex>
        <Icon onClick={onClose} className={style.close}>
          clear
        </Icon>
      </Layout>
    )
  )
}

// TODO: move to Flow types
// NotificationBar.propTypes = {
//   type: PropTypes.oneOf([
//     NotificationType.INFO,
//     NotificationType.WARNING,
//     NotificationType.ERROR,
//   ]).isRequired,
//   title: PropTypes.string,
//   titleId: PropTypes.string,
//   message: PropTypes.string,
//   messageId: PropTypes.string,
//   onClose: PropTypes.func,
// }

export default NotificationBar
