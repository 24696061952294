import React from 'react'
import Icon from '../Icon'
import Loading from '../Loading'
import classnames from 'classnames'

import style from './style.module.css'

/**
 * General styled button ("button" element)
 * @return {Component}
 */
export const Button = ({
  color = 'primary',
  size = 'normal',
  wide = false,
  outlined = false,
  uppercase = true,
  loading = false,
  compact = false,
  rounded = false,
  icon,
  className,
  children,
  disabled,
  ...props
}) => (
  <button
    className={classnames(
      className,
      style.button,
      style[`color-${color}`],
      style[`size-${size}`],
      {
        [style.outlined]: outlined,
        [style.uppercase]: uppercase,
        [style.rounded]: rounded,
        [style.wide]: wide,
        [style.disabled]: disabled || loading,
        [style.compact]: compact,
        [style.onlyIcon]: !!(!children && icon),
      }
    )}
    disabled={disabled || loading}
    {...props}
  >
    {icon ? <Icon className={style.icon}>{icon}</Icon> : null}
    {children}
    {loading ? <Loading className={style.loading} size="small" /> : null}
  </button>
)

// TODO: move to Flow types
// Button.propTypes = {
//   color: PropTypes.string,
//   size: PropTypes.string,
//   outlined: PropTypes.bool,
//   disabled: PropTypes.bool,
//   compact: PropTypes.bool,
//   rounded: PropTypes.bool,
//   wide: PropTypes.bool,
//   loading: PropTypes.bool,
//   uppercase: PropTypes.bool,
//   className: PropTypes.string,
//   icon: PropTypes.string,
//   children: PropTypes.node,
// }

export default Button
