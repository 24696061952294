import { LOAD_WHITELABELS_LIST } from 'app/redux-base/actions/whitelabelsList'

export const initialState = {
  data: [],
}

export default function whitelabelsList(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_WHITELABELS_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOAD_WHITELABELS_LIST.SUCCESS:
      return {
        ...state,
        data: action.response.data.results,
        loading: false,
        error: null,
      }

    case LOAD_WHITELABELS_LIST.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}
