import { CONFIGURATION_FORM_NAME } from 'app/constants'

export const getAppConfig = state => ({
  appConfig: state.configurator.appConfig,
})

export const getThemeCss = state => ({ themeCss: state.configurator.themeCss })

export const getFormValues = state => state.form[CONFIGURATION_FORM_NAME].values

export const getLoadingStatus = state => state.configurator.loading

export const getWlConfig = state => state.configurator.wlConfig

export const getAppVersionModel = state => state.configurator.appVersionModel

export const getFieldCategories = state => state.configurator.categories

export const getHasUnsavedChanges = state =>
  state.configurator.hasUnsavedChanges

export const getEmails = state => state.configurator.emails

export const getPreviewType = state => state.configurator.previewType
