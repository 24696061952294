import mapValues from 'lodash/mapValues'
import has from 'lodash/has'
import partial from 'lodash/partial'

// redux-form onSubmit should return promise that can be used for
// handling server-side validation errors
// http://redux-form.com/6.0.0-alpha.15/docs/api/SubmissionError.md/
export const addControllablePromise = action => {
  let resolvePromise = null
  let rejectPromise = null

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })

  return {
    ...action,
    promise,
    resolvePromise,
    rejectPromise,
  }
}

export const isPromise = val => val && typeof val.then === 'function'

/**
 * Creates a reducer from an initial state and a map that matches the action
 * type with the correct action handler, the handler must be a function with the
 * signature `handler(state: object, action: Action): object` and it returns the
 * properties that override the previous state. The handler could be split into
 * an object that handles three different states of actions with a promise
 * payload (`request`, `success` and `failure`).
 *
 * NOTE: async operations that aren't splitted by the action handler will ignore
 * the `request` state of the request.
 *
 * ```
 * const todoReducer = createReducer({
 *   todos: [],
 *   fetching: false
 * }, {
 *   [ADD_TODO]: (state, text) => ({
 *     todos: [text, ...state.todos]
 *   }),
 *   [GET_TODOS]: {
 *     request: () => ({ fetching: true }),
 *     success: (_, payload) => ({ todos: payload.todos }),
 *     failure: () => {
 *       alert('connection error!')
 *       return { fetching: false };
 *     }
 *   }
 * });
 * ```
 *
 * The `childReducers` will delegate the action to reducers passed on to it
 * that matches the key of the reducer with the context of the action and
 * modifies the property of the state that matches the same name.
 *
 * ```
 * const todoListsReducer = createReducer({
 *   currentList: 'home'
 * }, {
 *   [SET_TODO_LIST]: (_, currentList) => ({ currentList })
 * }, {
 *   home: todoReducer,
 *   work: todoReducer
 * });
 *
 * dispatch(addTodo('home', 'buy groceries'));
 * dispatch(addTodo('work', 'review PRs'));
 *
 * // resulting state:
 * {
 *   currentList: 'home',
 *   home: { todos: ['buy groceries'] },
 *   work: { todos: ['review PRs'] }
 * }
 * ```
 *
 * @param {Object} initialState - the reducers default state
 * @param {Object} actionHandlers - an action handler map
 * @param {Object} childReducers - reducers that will handle child states
 */
export const createReducer = (
  initialState,
  actionHandlers,
  childReducers = {}
) => {
  const finalInitialState = {
    ...initialState,
    ...mapValues(childReducers, (reducer, key) =>
      reducer(initialState[key], {})
    ),
  }

  return (state = finalInitialState, { namespace = [], ...action }) => {
    let reduceFn = actionHandlers[action.type]
    const key = namespace[0]
    let newState

    if (key && childReducers[key]) {
      newState = {
        ...state,
        [key]: childReducers[key](state[key], {
          ...action,
          namespace: namespace.slice(1),
        }),
      }
    } else if (reduceFn) {
      newState = {
        ...state,
        ...mapValues(childReducers, (reducer, k) => reducer(state[k], action)),
      }

      const isRequest = isPromise(action.payload)

      if (typeof reduceFn !== 'function') {
        if (isRequest) {
          reduceFn = reduceFn.request
        } else if (action.error) {
          reduceFn = reduceFn.failure
        } else {
          reduceFn = reduceFn.success
        }
      }
    } else {
      return state
    }

    if (!reduceFn) {
      return newState
    }

    return {
      ...newState,
      ...reduceFn(state, has(action, 'payload') ? action.payload : action, key),
    }
  }
}

/**
 * Defines an action creator with a `type` and a `payloadCreator` function
 * that maps the result of its evaluation to the action's payload, if no
 * function is provided then the payload is equal to the first parameter passed
 * to the action creator.
 *
 * The `namespaceCreator` function maps the actions arguments to it's `namespace` property,
 * this is specially useful when used in conjunction with child reducers
 * will traverse the state upon dispatch depending on the values of the namespace.
 * If no namespaceCreator is provided it defaults to an empty namespace
 *
 * If the middleware parameter is present then you can transform the action
 * being dispatched, it will be passed the action and returns a new action that
 * could either be a Promise, a function or an action object.
 * Used in conjunction with the promise and/or thunk middleware there's the
 * possibility of handling complex async logic.
 *
 * ```
 * const addTodo = createAction(
 *   ADD_TODO,
 *   (text) => text,
 *   (list) => [list]
 * );
 *
 * const getTodos = createAction(
 *   GET_TODOS,
 *   api.getTodos,
 *   undefined,
 *   action => async (dispatch) => {
 *     const todos = await dispatch(action);
 *     return dispatch(markAsSeen(todos));
 *   }
 * );
 * ```
 * @param {string} type
 * @param {function(...params)} [payloadCreator]
 * @param {function(...params): Array} [namespaceCreator]
 * @param {function(next)} [middleware]
 * @returns {ActionCreator}
 */
export const createAction = (
  type,
  payloadCreator = args => args,
  namespaceCreator = () => [],
  middleware = action => action
) => (...args) => {
  const namespace = namespaceCreator(...args)
  const nargs = args.length - namespace.length
  const payload = payloadCreator(...args.slice(namespace.length))
  const error = nargs === 1 && args[nargs - 1] instanceof Error
  return middleware({
    type,
    payload,
    error,
    namespace,
  })
}

/**
 * Will bind a `namespace` parameter to the given action or actions object.
 *
 * ```
 * addTodo('home', 'do the dishes');
 * // is the same as
 * bindNamespace(['home'], addTodo)('do the dishes');
 * ```
 * @param {string} namespace
 * @param {object|ActionCreator} action
 * @returns {ActionCreator}
 */
export const bindNamespace = (namespace, action) =>
  typeof action === 'function'
    ? partial(action, ...namespace)
    : mapValues(action, fn => partial(fn, ...namespace))
