import React from 'react'
import classnames from 'classnames'

import style from './style.module.css'

/**
 * General styled link ("a" element)
 * @return {Component}
 */
export const Link = ({
  dark = false,
  uppercase = false,
  className,
  children,
  ...props
}) => (
  <a
    className={classnames(style.link, style.default, className, {
      [style.dark]: dark,
      [style.uppercase]: uppercase,
    })}
    {...props}
  >
    {children}
  </a>
)

// TODO: move to Flow types
// Link.propTypes = {
//   dark: PropTypes.bool,
//   uppercase: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
// }

export default Link
