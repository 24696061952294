import axios from 'axios'
// import config from 'config';

class ApiClient {
  constructor() {
    ;['get', 'post', 'put', 'patch', 'delete'].forEach(method => {
      this[method] = (url, ...rest) => {
        let options = {
          // timeout: config.apiTimeout, //TODO: set defaults
        }

        if (this.token) {
          options = {
            ...options,
            headers: { Authorization: `JWT ${this.token}` },
          }
        }

        const instance = axios.create(options)

        return instance[method](url, ...rest)
      }
    })
  }

  getTokenFromState(state) {
    const { user } = state.auth
    this.token = user ? user.token : null
    return this
  }
}

export default ApiClient
