import React from 'react'
import GlobalNotification from 'app/components/containers/GlobalNotification'

const AppPage = ({ children }) => (
  <div style={{ height: '100%' }}>
    <div style={{ height: '100%' }}>
      <GlobalNotification />
      {children}
    </div>
  </div>
)

// TODO: move to Flow types
// AppPage.propTypes = {
//   children: PropTypes.element.isRequired,
// }

export default AppPage
