import React from 'react'
import classnames from 'classnames'
import ValidationError from '../ValidationError'

import style from './style.module.css'

const TextField = props => {
  const {
    color = 'normal',
    wide = true,
    type = 'text',
    warning,
    icon,
    className,
    rectangle,
    placeholder,
    staticPlaceholder,
    input: { value, ...restInputProps },
  } = props

  // check if field connected using redux-form, and check if field touched
  // if no redux-form - use error prop
  const isReduxFormField = 'input' in props && 'meta' in props

  const error = ValidationError({
    field: isReduxFormField && props,
    error: !isReduxFormField && props.error,
    className: style.error,
  })

  const hasValue = value && value.trim()

  return (
    <label
      className={classnames(
        className,
        style.container,
        style[`color-${color}`],
        {
          [style['container--rectangle']]: rectangle,
          [style['container--nonempty']]: hasValue,
          [style['container--warning']]: warning && warning.trim(),
          [style['container--error']]: error,
          [style['container--wide']]: wide,
          [style['container--icon']]: !!icon,
          [style['container--staticPlaceholder']]: !!staticPlaceholder,
        }
      )}
    >
      <span className={style.wrapper}>
        {icon && (
          <i className={classnames('material-icons', style.icon)}>{icon}</i>
        )}
        <input
          {...restInputProps}
          type={type}
          className={style.input}
          placeholder={placeholder}
          value={value}
        />
        {placeholder && !(hasValue && staticPlaceholder) && (
          <span className={style.placeholder}>{placeholder}</span>
        )}
        {warning && !error && <span className={style.warning}>{warning}</span>}
        {error}
      </span>
    </label>
  )
}

// TODO: move to Flow types
// TextField.propTypes = {
//   color: PropTypes.oneOf(['normal', 'inverse']),
//   type: PropTypes.oneOf(['text', 'password']),
//   wide: PropTypes.bool,
//   icon: PropTypes.string,
//   warning: PropTypes.string,
//   error: PropTypes.string,
//   className: PropTypes.string,
//   placeholder: PropTypes.string,
//   rectangle: PropTypes.bool,
//   staticPlaceholder: PropTypes.bool,
//   // redux-form field props
//   input: PropTypes.shape({
//     value: PropTypes.string,
//     onChange: PropTypes.func.isRequired,
//   }),
// }

export default TextField
