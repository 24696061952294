import React from 'react'
import * as Navigation from 'app/components/base/Navigation'
import { Layout } from 'app/components/base/Layout'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { logout } from 'app/redux-base/actions/loginFlow'
import { withRouter } from 'react-router'

import style from './style.module.css'

const UserLinks = ({ user, dispatch }) => {
  if (user) {
    return (
      <span>
        <Navigation.Link key="1" to="/userAccount">
          <Layout align="center center">
            <i className={classnames('material-icons', style.userIcon)}>
              account_circle
            </i>
            {user.username}
          </Layout>
        </Navigation.Link>
        <Navigation.Button key="2" onClick={() => dispatch(logout())}>
          Sign out
          <i className={classnames('material-icons', style.logoutIcon)}>
            exit_to_app
          </i>
        </Navigation.Button>
      </span>
    )
  }

  return (
    <Navigation.Link to="/login">
      Sign in
      <i className="material-icons">exit_to_app</i>
    </Navigation.Link>
  )
}

// TODO: move to Flow types
// UserLinks.propTypes = {
//   user: PropTypes.object,
//   dispatch: PropTypes.func.isRequired,
// }

export const NavigationContainer = props => (
  <Navigation.Container onClickLogo={() => props.history.replace('/')}>
    <Navigation.ButtonsGroup position="left">
      <Navigation.Button small inactive>
        whitelabel
        <br />
        administration tool
      </Navigation.Button>
    </Navigation.ButtonsGroup>
    <Navigation.ButtonsGroup position="right">
      <Navigation.Link to="/dashboard">Dashboard</Navigation.Link>
      <Navigation.Link to="/create">Create whitelabel</Navigation.Link>
      <UserLinks {...props} />
    </Navigation.ButtonsGroup>
  </Navigation.Container>
)

export default withRouter(
  connect(({ auth }) => ({ user: auth.user }))(NavigationContainer)
)
