import { LOGIN, LOGOUT } from '../actions/loginFlow'

export default function auth(state = {}, action = {}) {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case LOGIN.SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        error: null,
      }

    case LOGIN.FAILURE:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.error,
      }

    case LOGOUT:
      return {
        ...state,
        loading: false,
        error: null,
        user: null,
      }

    default:
      return state
  }
}
