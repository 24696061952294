import { call, cancel, fork, put, take } from 'redux-saga/effects'
import authorize from './authorize'
import { LOGIN, LOGOUT, loginSuccess } from 'app/redux-base/actions/loginFlow'
import { getHistory } from 'app/history'
import ApiToken from 'app/utils/ApiToken'

export default function* loginFlow() {
  while (true) {
    // eslint-disable-line  no-constant-condition
    const apiToken = yield new ApiToken()

    // check cookies and try to autologin
    const user = yield call(apiToken.getFromCookie().getUser)

    if (user) {
      yield put(loginSuccess(user))
    }

    const authorizeTask = yield fork(authorize)

    // wait for failed login attempt or logout action
    const action = yield take([LOGIN.FAILURE, LOGOUT])

    // if logout action performed - make redirection, remove cookie
    // and kill backgroound task that listens login request action.
    // It will automatically start in next loop iteration
    if (action.type === LOGOUT) {
      yield cancel(authorizeTask)

      yield call(apiToken.removeCookie)

      const history = getHistory()
      yield call(history.replace, '/login')
    }
  }
}
