import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Navigation from 'app/components/containers/Navigation'
import WhitelabelGridItem from 'app/components/dashboard/WhitelabelGridItem'
import { Layout, Flex, Container } from 'app/components/base/Layout'
import Loading from 'app/components/base/Loading'
import { withRouter } from 'react-router'
import { loadWhitelabelsListRequest } from 'app/redux-base/actions/whitelabelsList'
import requireAuth from 'app/components/HOCs/requireAuth'

import style from './style.module.css'

const LoadingContainer = () => {
  return (
    <Container align="center cetner" nowrap>
      <Flex className={style.loading}>
        <Loading size="large" />
      </Flex>
    </Container>
  )
}

export class DashboardPage extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.dispatch(loadWhitelabelsListRequest())
  }

  getThumbnail = item =>
    item.thumbnail ? `${item.thumbnail}?${item.updated_at}` : undefined

  handleClickCreate = () => {
    const { history } = this.props
    history.push('/create')
  }

  handleClickPreview = name => () => {
    const wlUrl = `https://www.mozio.com/?wl=${name}`
    window.open(wlUrl, '_blank')
  }

  handleClickEdit = id => () => {
    const { history } = this.props
    history.push(`/configuration/${id}`)
  }

  renderWhitelabels() {
    const { items } = this.props
    return (
      <Container direction="row" nowrap>
        <Flex size="grow" />
        <Flex size="noshrink" className={style.whitelabels}>
          <Layout direction="row" align="start start">
            <div className={style.whitelabels__item}>
              <WhitelabelGridItem
                create
                onClickCreate={this.handleClickCreate}
              />
            </div>
            {items.map((item, index) => (
              <div className={style.whitelabels__item} key={index}>
                <WhitelabelGridItem
                  onClickPreview={this.handleClickPreview(item.name)}
                  onClickEdit={this.handleClickEdit(item.id)}
                  name={item.name}
                  thumbnail={this.getThumbnail(item)}
                  offline={!item.is_live}
                />
              </div>
            ))}
          </Layout>
        </Flex>
        <Flex size="grow" className={style.tools}>
          <div>
            <div className={style.tools__button}>
              <i className="material-icons">search</i>
            </div>
          </div>
          <div>
            <div className={style.tools__button}>
              <i className="material-icons">list</i>
            </div>
          </div>
        </Flex>
      </Container>
    )
  }

  render() {
    const { loading } = this.props
    return (
      <Layout fill nowrap direction="column" className={style.container}>
        <Flex size="noshrink">
          <Navigation />
        </Flex>
        <Container size="grow" className={style.dashboard}>
          {loading ? <LoadingContainer /> : this.renderWhitelabels()}
        </Container>
      </Layout>
    )
  }
}

// TODO: move to Flow types
// DashboardPage.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.object).isRequired,
//   dispatch: PropTypes.func.isRequired,
//   loading: PropTypes.bool,
// }

const mapStateToProps = state => ({
  items: state.whitelabelsList.data,
  loading: state.whitelabelsList.loading,
})

export default compose(
  withRouter,
  requireAuth(),
  connect(mapStateToProps)
)(DashboardPage)
