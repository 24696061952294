import config from 'config'
import { fork, put, take } from 'redux-saga/effects'
import apiRequest from '../../common/apiRequest'
import NotificationType from 'app/redux-base/enums/NotificationType'
import { LOAD_WHITELABELS_LIST } from 'app/redux-base/actions/whitelabelsList'
import {
  showNotification,
  clearNotifications,
} from 'app/redux-base/actions/notifications'

export default function* loadWhitelabels() {
  const url = `${config.BASE_URL_V2}/whitelabels/whitelabel/`

  const apiCall = apiClient => apiClient.get(url)
  yield apiCall

  yield fork(apiRequest, apiCall, LOAD_WHITELABELS_LIST)

  const { error } = yield take(LOAD_WHITELABELS_LIST.FAILURE)

  if (error) {
    yield put(
      showNotification({
        type: NotificationType.ERROR,
        title: 'Loading whitelabels failed',
        message: error,
      })
    )
  } else {
    yield put(clearNotifications())
  }
}
