import React from 'react'
import classnames from 'classnames'
import style from './style.module.css'

const ValidationError = ({ field, error, className }) => {
  const errorMessage =
    field && field.meta && field.meta.touched && field.meta.error
      ? field.meta.error
      : error

  if (errorMessage) {
    return (
      <span className={classnames(style.error, className)}>{errorMessage}</span>
    )
  }
  return null
}

// TODO: move to Flow types
// ValidationError.propTypes = {
//   field: PropTypes.object,
//   error: PropTypes.string,
//   className: PropTypes.string,
// }

export default ValidationError
