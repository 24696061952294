import React from 'react'
import { Layout } from '../Layout'
import classnames from 'classnames'

import style from './style.module.css'

/**
 * Stylish animated Accordion component.
 * It's a container for items, that controls who is opened
 * and responds to click of item headers.
 *
 * You can define which items should be opened by default with
 * `defaultOpened` property. It accepts an array of item indexes
 * that should be opened by first render.
 */
export class Accordion extends React.Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = { opened: new Set(props.defaultOpened || []) }
  }

  handleClickItemHeader = index => {
    const nextOpened = new Set(this.state.opened)

    if (nextOpened.has(index)) {
      nextOpened.delete(index)
    } else {
      nextOpened.add(index)
    }

    this.setState({ opened: nextOpened })
  }

  prepareChildren() {
    const { opened } = this.state
    return React.Children.map(this.props.children, (ch, i) =>
      React.cloneElement(ch, {
        key: i,
        onClickHeader: this.handleClickItemHeader,
        index: i,
        opened: opened.has(i),
      })
    )
  }

  render() {
    const children = this.prepareChildren()
    const { className, ...props } = this.props

    return (
      <Layout
        nowrap
        direction="column"
        className={classnames(className, style.container)}
        {...props}
      >
        {children}
      </Layout>
    )
  }
}

// TODO: move to Flow types
// Accordion.propTypes = {
//   defaultOpened: PropTypes.array,
//   className: PropTypes.string,
//   children: PropTypes.node,
// }

export default Accordion
