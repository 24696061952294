import config from 'config'
import { call, put } from 'redux-saga/effects'
import apiRequest from 'app/redux-base/sagas/common/apiRequest'
import {
  loadEmailTemplatesRequest,
  loadEmailTemplatesSuccess,
} from 'app/redux-base/actions/configurator'

export default function* loadEmailTemplates(id) {
  yield put(loadEmailTemplatesRequest())

  const loadEmailsUrl = `${config.BASE_URL_V2}/whitelabels/whitelabel/${id}/emails/`
  const loadEmailsUrlApiCall = apiClient => apiClient.get(loadEmailsUrl)
  yield loadEmailsUrlApiCall

  const response = yield call(apiRequest, loadEmailsUrlApiCall)
  if (!response.error) {
    const emails = response.response.data
    yield put(loadEmailTemplatesSuccess(emails))
  }

  return response
}
