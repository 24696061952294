import React from 'react'
import { CustomPicker } from 'react-color'
import { Saturation, Hue } from 'react-color/lib/components/common'
import classnames from 'classnames'

import style from './style.module.css'
import SliderPointer from 'react-color/lib/components/slider/SliderPointer'

const ColorPicker = ({ className, ...props }) => (
  <div className={classnames(className, style.picker)}>
    <div className={style.picker__saturation}>
      <Saturation {...props} />
    </div>
    <div className={style.picker__hue}>
      <Hue {...props} direction="horizontal" pointer={SliderPointer} />
    </div>
  </div>
)

// TODO: move to Flow types
// ColorPicker.propTypes = {
//   className: PropTypes.string,
//   hex: PropTypes.string,
// }

export default CustomPicker(ColorPicker)
