import React from 'react'
import TextField from 'app/components/base/TextField'
import FieldBase from '../../commons/FieldBase'

export const StringRenderer = ({ field, ...props }) => (
  <FieldBase label={field.description} direction="column">
    <TextField
      {...props}
      placeholder={field.placeholder}
      staticPlaceholder
      rectangle
      wide
    />
  </FieldBase>
)

// TODO: move to Flow types
// StringRenderer.propTypes = {
//   field: PropTypes.object,
// }

export default StringRenderer
