// TODO: maybe read in other way in case if sdk will use another baseUrl
import config from 'config'
import { call, put, take } from 'redux-saga/effects'
import { getHistory } from 'app/history'
import apiRequest from '../common/apiRequest'
import {
  LOGIN,
  loginSuccess,
  loginFailure,
} from 'app/redux-base/actions/loginFlow'
import NotificationType from 'app/redux-base/enums/NotificationType'
import {
  showNotification,
  clearNotifications,
} from 'app/redux-base/actions/notifications'
import ApiToken from 'app/utils/ApiToken'

export default function* authorize() {
  const { email, password, rememberMe } = yield take(LOGIN.REQUEST)

  const authUrl = `${config.BASE_URL_V2}/auth/token/`
  const params = { email, password }

  const authApiCall = apiClient => apiClient.post(authUrl, params)
  yield authApiCall

  const { response, error } = yield call(apiRequest, authApiCall)

  if (response) {
    const apiToken = yield new ApiToken(response.data.token)
    const user = apiToken.getUser()

    yield put(loginSuccess(user))

    if (rememberMe) {
      yield call(apiToken.setCookie)
    }

    yield put(clearNotifications())

    const history = getHistory()
    yield call(history.replace, '/dashboard')
  } else {
    yield put(loginFailure(error.message))
    yield put(
      showNotification({
        type: NotificationType.ERROR,
        title: 'Login failed',
        message: error.message,
      })
    )
  }
}
