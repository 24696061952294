import React from 'react'
import SelectField from 'app/components/base/SelectField'
import FieldBase from '../../commons/FieldBase'

export const SelectRenderer = ({ field, ...props }) => (
  <FieldBase label={field.description} align="start center">
    <SelectField options={field.options} {...props} />
  </FieldBase>
)

// TODO: move to Flow types
// SelectRenderer.propTypes = {
//   field: PropTypes.object,
// }

export default SelectRenderer
