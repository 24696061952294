import appConfig from 'config'

import { call, put } from 'redux-saga/effects'
import apiRequest from '../../common/apiRequest'
import {
  SAVE_WHITELABEL,
  setConfigurationCondition,
} from 'app/redux-base/actions/configurator'
import NotificationType from 'app/redux-base/enums/NotificationType'
import { showNotification } from 'app/redux-base/actions/notifications'
import { SubmissionError } from 'redux-form'
import processConfig from './processConfig'

export default function* saveWL(action) {
  const { wlConfig } = action
  const url = `${appConfig.BASE_URL_V2}/whitelabels/whitelabel/${wlConfig.id}/`
  const { config, versionId } = yield call(processConfig, action)

  if (!config) {
    action.rejectPromise(new SubmissionError())
    yield put({
      ...action,
      type: SAVE_WHITELABEL.FAILURE,
      error: 'Config processing failed',
    })
    return
  }

  const params = {
    thumbnail: null,
    version: versionId,
    config,
  }

  const apiCall = apiClient => apiClient.patch(url, params)
  yield apiCall

  const { response, error, errorData } = yield call(
    apiRequest,
    apiCall,
    SAVE_WHITELABEL,
    action
  )

  if (response) {
    yield put(
      showNotification({
        type: NotificationType.INFO,
        message: 'Changes successfully saved',
      })
    )

    const hasChanges = false
    yield put(setConfigurationCondition(hasChanges))

    action.resolvePromise()
  } else {
    // handling server-side validation
    const validationErrors = Object.keys(errorData || {}).reduce(
      (errors, field) => ({
        ...errors,
        [field]: errorData[field][0],
      }),
      {}
    )

    // show common error
    if (error) {
      yield put(
        showNotification({
          type: NotificationType.ERROR,
          title: 'Save whitelabel failed',
          message:
            validationErrors.config || error.message || 'unkonwn server error',
        })
      )
    }

    action.rejectPromise(new SubmissionError(validationErrors))
  }
}
