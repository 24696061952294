import React from 'react'

import Switch from 'app/components/base/Switch'
import Button from 'app/components/base/Button'
import { Layout } from 'app/components/base/Layout'

import style from './style.module.css'

class SwitchButton extends React.Component {
  state = {
    value: this.props.input.value,
  }

  handleButtonClick = () => {
    const newValue = !this.state.value
    this.handleChange(newValue)
  }

  handleChange = value => {
    this.setState({ value })

    const onChange = this.props.onChange || this.props.input.onChange
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { label, loading } = this.props
    return (
      <Button
        size="small"
        color={this.state.value ? 'green' : 'lightgray'}
        onClick={this.handleButtonClick}
        loading={loading}
      >
        <Layout
          direction="row"
          align="center center"
          className={style.container}
        >
          <span className={style.label}>{label}</span>
          <Switch
            input={{
              onChange: this.handleChange,
              value: this.state.value,
            }}
            onColor="green"
          />
        </Layout>
      </Button>
    )
  }
}

// TODO: move to Flow types
// SwitchButton.propTypes = {
//   label: PropTypes.string,
//   loading: PropTypes.bool,
//   onChange: PropTypes.func,
//   // redux-form field props
//   input: PropTypes.shape({
//     value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//     onChange: PropTypes.func.isRequired,
//   }),
// }

export default SwitchButton
